<template>
  <AddFunds
    v-if="funds.status"
    @success="successAddFundsModal"
    @close="closeAddFundsModal"
    :cash="funds.cash"/>
  <SuccessModal v-if="alert.status && alert.type === 'alert-success'"/>
  <section class="new-order-view">
    <div class="row">
      <div class="col-12">
        <h1 class="view-name">New Order</h1>
        <p>Select a category and service to proceed with your order.</p>
      </div>
    </div>
    <Form @submit="onSubmit" :validation-schema="schema" class="needs-validation">
      <div class="row">
        <div class="col-12 col-lg-8">
          <div class="row">
            <div class="col-12">
              <div class="card border-0 mb-3">
                <div class="card-body">
                  <div class="mb-3">
                    <h3 class="mb-1">Configure</h3>
                    <div class="row">
                      <div class="col-12 col-lg-4 mb-4 mb-lg-0">
                        <SelectInput
                          v-model="input.category_id"
                          name="category_id"
                          :options="categories"
                          placeholder="Pick a category"
                        />
                      </div>
                      <div class="col-12 col-lg-8">
                        <SelectInput
                          v-model="input.service_id"
                          name="service_id"
                          :options="services"
                          placeholder="Pick a service"
                        />
                      </div>
                      <div
                        v-if="service && service.description"
                        class="col-12 mt-3">
                        <div class="service_desc" v-html="service.description"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="card border-0 mb-3">
                <div class="card-header bg-transparent border-0">
                  <div class="d-flex justify-content-between">
                    <div class="card-header-title"></div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="mb-3">
                    <SimpleInput
                      v-model="input.url"
                      type="text"
                      label="URL"
                      name="url"

                      placeholder="https://www.youtube.com/watch?v=_xyz"
                    />
                  </div>
                  <div class="mb-3">
                    <SimpleInput
                      v-model="input.quantity"
                      type="number"
                      label="Quantity"
                      name="quantity"

                      :placeholder="(service) ? service.min : 0"
                      :min="(service) ? service.min : 0"
                      :max="(service) ? service.max : 0"
                      :float-text-right="this.centsToDollar(input.cost)"
                      :bottom-text-left="`Min ${ (service) ? service.min : '00,000' } - Max ${ (service) ? service.max : '00,000' }`"
                      :bottom-text-right="`Cost per ${ (service) ? service.interval : '0,000' } is ${ (service) ? costPerInterval(service.cost, service.interval) : '$0,000' }`"
                    />
                  </div>
                  <div class="mb-5">
                    <TextareaInput
                      v-model="input.notes"
                      label="Notes"
                      name="notes"

                      :placeholder="(service) ? service.notes : null"
                    />
                  </div>
                  <div
                    v-if="service && service.addons.length > 0"
                    class="addons">
                    <div class="mb-3">
                      <h3 class="m-0">Service Addons</h3>
                      <label for="labelService2" class="form-text form-label-small m-0">Get discount on adding more
                        services!</label>
                    </div>
                    <div
                      v-for="addon in service.addons"
                      :key="addon.id">
                      <div class="mb-3 d-flex align-items-center">
                        <input
                          @change="addonCheckHandler($event)"
                          class="form-check-input"
                          type="checkbox"
                          :value="addon.id"
                          :id="`addon_check_${addon.id}`"
                          v-model="addonsChecked">
                        <label
                          class="form-check-label"
                          :for="`addon_check_${addon.id}`">
                          {{ addon.name }}
                        </label>
                      </div>
                      <div
                        v-if="getIndexOfAddon(addon.id) !== -1">
                        <div class="mb-3">
                          <SimpleInput
                            v-model="input.addons[getIndexOfAddon(addon.id)].quantity"
                            type="number"
                            label="Quantity"
                            :name="`addons.service${addon.id}`"

                            :placeholder="addon.min"
                            :min="addon.min"
                            :max="addon.max"
                            :float-text-right="this.centsToDollar(input.addons[getIndexOfAddon(addon.id)].cost)"
                            :bottom-text-left="`Min ${ addon.min } - Max ${ addon.max }`"
                            :bottom-text-right="`Cost per ${ addon.interval } is ${ costPerInterval(addon.cost, addon.interval) }`"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="card border-0 mb-3 position-sticky order_summary_div">
                <h3 class="title-order">Order Summary</h3>
            <div class="card-body">
              <div class="mb-3 pt-3">
                <div
                  v-if="this.service"
                  class="d-flex justify-content-between">
                  <h5>{{ input.quantity }} {{ (this.service) ? this.service.name : null }}</h5>
                  <h6>{{ this.centsToDollar(input.cost) }}</h6>
                </div>
                <div
                  v-for="addon in this.input.addons"
                  :key="addon.id"
                  class="d-flex justify-content-between">
                  <h5>{{ addon.quantity }} {{ this.service.addons[this.getIndexOfAddon(addon.service_id)].name }}</h5>
                  <h6>{{ this.centsToDollar(addon.cost) }}</h6>
                </div>
              </div>
              <div class="mb-4 pt-4 border-top">
                <div
                  v-if="currentUser.active_subscription !== null"
                  class="d-flex justify-content-between">
                  <h5>Sub Total</h5>
                  <h6>{{ centsToDollar(this.getSubTotalCost()) }}</h6>
                </div>
                <div
                  v-if="currentUser.active_subscription !== null"
                  class="d-flex justify-content-between">
                  <h5>Subscription Discount</h5>
                  <h6>{{ currentUser.active_subscription.plan.discount }}%</h6>
                </div>

                <div
                  v-if="coupon.instance !== null"
                  class="d-flex justify-content-between">
                  <h5>Coupon Discount</h5>
                  <h6>{{ coupon.instance.discount }}<span v-if="coupon.instance.discount_type === 'percentage'">%</span><span v-else>$</span></h6>
                </div>

                <div class="d-flex justify-content-between">
                  <h5 class="total">Total Amount</h5>
                  <h6>{{ centsToDollar(getTotalCost()) }}</h6>
                </div>

                <div
                  v-if="!coupon.display"
                  class="d-flex justify-content-between">
                  <h5
                    @click="coupon.display = true"
                    class="cursor-pointer text-decoration-underline">Apply Coupon</h5>
                  <h5
                    v-if="coupon.instance !== null"
                    @click="coupon.instance = null"
                    class="cursor-pointer text-decoration-underline">Clear Coupon</h5>
                </div>
                <div
                  v-if="coupon.display"
                  class="d-flex justify-content-between coupon">
                  <input
                    v-model="coupon.input"
                    class="form-control"
                    type="text"
                    placeholder="coupon code">
                  <button
                    @click="coupon.display = false"
                    class="btn btn-danger">&cross;</button>
                  <button
                    @click.prevent="applyCoupon"
                    class="btn btn-primary">
                    <span v-if="coupon.loading" class="spinner-border spinner-border-sm"></span>
                    <span v-else>Apply</span>
                  </button>
                </div>
              </div>
              <div class="mb-4 pt-4 border-top">
                <div class="d-flex justify-content-between">
                  <h5 class="funds">Available Funds</h5>
                  <h6 class="funds">{{ centsToDollar(currentUser.cash) }}</h6>
                </div>
              </div>
              <div class="mb-4 pt-4">
                <div class="row">
                  <div class="col-12">
                    <div
                      v-if="alert.status && alert.type === 'alert-danger'"
                      class="form-group mb-4">
                      <div
                        :class="alert.type"
                        class="alert"
                        role="alert">
                        {{ alert.message }}
                      </div>
                    </div>

                    <button
                      class="btn btn-custom btn-order w-100 p-3">
                    <span
                      v-show="loading"
                      class="spinner-border spinner-border-sm">
                    </span>
                      <span
                        v-show="!loading">
                      Place Order
                    </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  </section>
</template>

<script>
import { Form } from 'vee-validate'
import * as yup from 'yup'
import SelectInput from '@/components/Form/SelectInput'
import SimpleInput from '@/components/Form/SimpleInput'
import TextareaInput from '@/components/Form/TextareaInput'
import SuccessModal from '@/components/Modals/SuccessModal'
import AddFunds from '@/components/Modals/AddFunds'

export default {
  name: 'NewOrderView',
  components: {
    AddFunds,
    SuccessModal,
    SelectInput,
    SimpleInput,
    TextareaInput,
    Form
  },
  data () {
    return {
      schema: yup.object({
        category_id: yup.string().required('Category is a required field'),
        service_id: yup.string().required('Service is a required field'),
        url: yup.string().required('Url is a required field'),
        // .matches(
        //   /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        //   'Invalid url entered'
        // )
        quantity: yup.number().typeError('Quantity must be a number').required('Quantity is a required field').min(1, 'Quantity must be greater than or equal to 1').max(1, 'Quantity must be less than or equal to 1').nullable(),
        notes: yup.string().nullable(),
        addons: yup.object({})
      }),
      loading: false,
      coupon: {
        loading: false,
        display: false,
        instance: null,
        input: ''
      },
      alert: {
        status: false,
        type: 0,
        message: null,
        timeout: null
      },
      addonsChecked: [],
      input: {
        category_id: '',
        service_id: '',
        cost: null,
        url: '',
        quantity: null,
        notes: '',
        addons: []
      },
      category: null,
      service: null,
      services: [],

      funds: {
        status: false,
        cash: 0
      }
    }
  },
  methods: {
    centsToDollar (cents) {
      return this.$centsToDollar(cents)
    },
    addon (serviceID) {
      return this.service.addons.find(el => {
        return parseInt(el.id) === parseInt(serviceID)
      })
    },
    getIndexOfAddon (serviceID) {
      return this.input.addons.findIndex(el => {
        return parseInt(el.service_id) === parseInt(serviceID)
      })
    },
    dispatchCategoryServiceShow (itemID) {
      this.$store.dispatch('categoryService/show', itemID).then(() => {
        this.schema.fields.quantity = yup.number().typeError('Quantity must be a number').required('Quantity is a required field').min(this.service.min, `Quantity must be greater than or equal to ${this.service.min}`).max(this.service.max, `Quantity must be less than or equal to ${this.service.max}`).nullable()
      })
    },
    costPerInterval (cost, interval) {
      return ((parseInt(cost) / 100) * parseInt(interval)).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD'
      })
    },
    processCost (quantity, cost) {
      return (parseInt(quantity) * parseInt(cost))
    },
    getSubTotalCost () {
      const totalCostAddons = this.input.addons.reduce((accumulator, el) => {
        return accumulator + parseInt(el.cost)
      }, 0)
      return this.input.cost + totalCostAddons
    },
    getTotalCost () {
      const totalCostAddons = this.input.addons.reduce((accumulator, el) => {
        return accumulator + parseInt(el.cost)
      }, 0)
      const subTotal = parseInt(this.input.cost + totalCostAddons)
      let total = subTotal

      if (this.currentUser.active_subscription !== null) {
        const discount = subTotal * parseInt(this.currentUser.active_subscription.plan.discount) / 100
        total = total - discount
      }
      if (this.coupon.instance !== null) {
        if (this.coupon.instance.discount_type === 'percentage') {
          const discount = subTotal * parseInt(this.coupon.instance.discount) / 100
          total = total - discount
        } else {
          const discount = parseInt(this.coupon.instance.discount) * (10 ** 2)
          total = total - discount
        }
      }

      return total
    },
    onSubmit (values = null, event = null, fundSuccess = false) {
      this.loading = true
      this.alert.status = false
      this.alert.type = null
      this.alert.message = null
      const formData = this.input

      formData.addons = formData.addons.filter(element => {
        return element !== null && element !== undefined && Object.keys(element).length !== 0
      })

      if (this.coupon.instance !== null) {
        formData.coupon_id = this.coupon.instance.id
      } else {
        formData.coupon_id = null
      }

      console.log(this.coupon)
      console.log('this.category', this.category)
      console.log(formData)
      console.log(this.currentUser.cash)
      console.log(this.getTotalCost(), fundSuccess)
      if (this.category.trail === 'paid' && !fundSuccess && this.currentUser.cash < this.getSubTotalCost()) {
        console.log('add funds')
        this.funds.status = true
        this.funds.cash = this.getSubTotalCost() - this.currentUser.cash
        this.loading = false
        return
      }

      this.$store.dispatch('order/store', formData).then(
        (res) => {
          this.category = null
          this.service = null
          this.services = []
          this.input.category_id = ''
          this.input.service_id = ''
          this.input.cost = null
          this.input.url = ''
          this.input.quantity = null
          this.input.notes = ''
          this.input.addons = []
          this.addonsChecked = []

          this.coupon.input = ''
          this.coupon.instance = null
          this.coupon.display = false
          this.coupon.loading = false

          this.loading = false
          this.alert.status = true
          this.alert.type = 'alert-success'
          this.alert.message = 'Order placed successfully'
        },
        (error) => {
          this.alert.message = ''

          if (typeof error.errors !== 'undefined') {
            const errors = error.errors
            Object.keys(errors).forEach((errorKey) => {
              errors[errorKey].forEach((errVal, errIdx) => {
                this.alert.message += (this.alert.message === '') ? errVal : ', ' + errVal
              })
            })
          } else if (typeof error.message !== 'undefined') {
            this.alert.message = error.message
          }

          this.alert.type = 'alert-danger'
          this.alert.status = true
          this.loading = false
        }
      )
    },
    addonCheckHandler (e) {
      const serviceID = e.target.value
      const schemaAddons = this.schema.fields.addons.fields
      const found = this.input.addons.some(el => el.service_id === serviceID)

      if (found && e.target.checked === false) {
        this.input.addons = this.input.addons.filter(function (el) {
          return el.service_id !== serviceID
        })
        delete schemaAddons['service' + serviceID]
      } else if (!found) {
        this.input.addons.push({
          service_id: serviceID,
          quantity: null,
          cost: null
        })
        schemaAddons['service' + serviceID] = yup.number().typeError('Quantity must be a number').required('Quantity is a required field').min(this.addon(serviceID).min, `Quantity must be greater than or equal to ${this.addon(serviceID).min}`).max(this.addon(serviceID).max, `Quantity must be less than or equal to ${this.addon(serviceID).max}`).nullable()
      }

      this.schema.fields.addons = yup.object(schemaAddons)
    },
    successAddFundsModal () {
      console.log('ttt')
      this.onSubmit(null, null, true)
    },
    closeAddFundsModal () {
      this.funds.status = false
    },
    debug (arg) {
      console.log(arg)
    },

    /* coupons */
    applyCoupon () {
      this.coupon.loading = true
      this.alert.status = false
      this.alert.type = null
      this.alert.message = null

      if (this.coupon.input === '') {
        this.alert.status = true
        this.alert.type = 'alert-danger'
        this.alert.message = 'Coupon code is required.'
        this.coupon.loading = false
      } else {
        this.$store.dispatch('coupon/show', this.coupon.input).then((res) => {
          this.coupon.input = ''
          this.coupon.instance = res
          this.coupon.loading = false
          this.coupon.display = false
        }).catch(error => {
          this.alert.message = ''

          if (typeof error.errors !== 'undefined') {
            const errors = error.errors
            Object.keys(errors).forEach((errorKey) => {
              errors[errorKey].forEach((errVal, errIdx) => {
                this.alert.message += (this.alert.message === '') ? errVal : ', ' + errVal
              })
            })
          } else if (typeof error.message !== 'undefined') {
            this.alert.message = error.message
          }

          this.alert.type = 'alert-danger'
          this.alert.status = true
          this.coupon.loading = false
        })
      }
    }
  },
  mounted () {
  },
  computed: {
    currentUser () {
      return this.$store.state.user.row
    },
    categories () {
      return this.$store.state.category.rows
    }
  },
  watch: {
    'input.category_id': function (val, oldVal) {
      if (val !== '') {
        this.category = this.categories.find((row) => {
          return parseInt(row.id) === parseInt(val)
        })
        this.service = null
        this.services = (this.category.services !== null) ? this.category.services : []
        this.input.service_id = ''
        this.input.cost = null
        this.input.url = ''
        this.input.quantity = null
        this.input.notes = ''
        this.input.addons = []
        this.addonsChecked = []
      }
    },
    'input.service_id': function (val, oldVal) {
      if (val !== '') {
        this.service = this.services.find((row) => {
          return parseInt(row.id) === parseInt(val)
        })
        this.input.cost = null
        this.input.url = ''
        this.input.quantity = null
        this.input.notes = ''
        this.input.addons = []
        this.addonsChecked = []
        if (this.service) {
          this.schema.fields.quantity = yup.number().typeError('Quantity must be a number').required('Quantity is a required field').min(this.service.min, `Quantity must be greater than or equal to ${this.service.min}`).max(this.service.max, `Quantity must be less than or equal to ${this.service.max}`).nullable()
        }
      }
    },
    'input.quantity': function (val, oldVal) {
      if (val !== '' && this.service) {
        this.input.cost = this.processCost(val, this.service.cost)
      }
    },
    // 'input.addons.*.quantity': function (val, oldVal) {
    //   console.log('input.addons.*.quantity', val)
    //   if (val !== '') {
    //     // input.addons[getIndexOfAddon(addon.id)].cost
    //     // this.input.addons[].cost = this.processCost(val, this.service.cost)
    //   }
    // },

    'input.addons': {
      deep: true,
      handler: function (after) {
        for (let index = 0; index < after.length; index++) {
          const addon = after[index]
          console.log('watch addon ', addon)
          if (addon.quantity !== null && addon.quantity !== '' && addon.quantity !== 0) {
            this.input.addons[index].cost = this.processCost(addon.quantity, this.service.addons[this.getIndexOfAddon(addon.service_id)].cost)
          }
        }
      }
    }
  }
}
</script>

<style scoped>
h3 {
  font-size: 18px;
  font-weight: bold;
  color: var(--text-title);
}

h5 {
  font-size: 14px;
  font-weight: normal;
  color: var(--text-default);
}

h6 {
  font-size: 14px;
  font-weight: bold;
  color: var(--text-title);
}

input {
  font-size: 14px;
  font-weight: normal;
  border-radius: 7px;
  background-color: #f7f7f7;
  border-color: var(--border-default);
  color: var(--text-default);
}

.form-check-label {
  font-size: 14px;
  font-weight: normal;
  color: var(--text-default);
}

input[type="checkbox"] {
  margin: unset;
}

input:focus {
  font-size: 14px;
  font-weight: normal;
  border-radius: 6px;
  color: var(--text-default);
  outline: unset;
  box-shadow: unset;
}

input[type="checkbox"] {
  margin-right: 10px;
}

.form-text {
  font-size: 11px;
  font-weight: normal;
  color: var(--text-default);
}

.form-label-small {
  font-size: 14px;
}

.position-sticky {
  top: 20px;
}

.total {
  color: var(--text-title);
}

.funds {
  font-size: 14px;
  font-weight: bold;
  color: var(--green-default);
}

.btn-order {
  font-size: 22px;
  font-weight: bold;
  border-radius: 8px;
}

.coupon .btn-danger {
  border-radius: unset;
}

.coupon .btn-primary {
  border-top-left-radius: unset;
  border-bottom-left-radius: unset;
}

.coupon input {
  font-size: 14px;
  font-weight: normal;
  border-radius: 7px 0 0 7px;
  background-color: #f7f7f7;
  border-color: var(--border-default);
  color: var(--text-default);
}

input:focus {
  border: 1px solid #dde3ff !important;
  background:#FFFFFF !important;
}

text-area:focus {
  border: 1px solid #dde3ff !important;
  background:#FFFFFF !important;
}

select:focus {
  border: 1px solid #dde3ff !important;
  background:#FFFFFF !important;
}

</style>
