<template>
  <div class="password-reset-view">
    <div class="body-auth">
      <div class="box-window justify-content-center align-items-center">
        <div>
          <div class="d-flex justify-content-center mb-4">
            <img src="@/assets/logo.svg" alt="Sixerr">
          </div>
          <div class="box">
            <h1>Lost Password Reset</h1>
            <p>Enter your email address below to begin the reset process.</p>
            <form @submit.prevent="submitForm">
              <div
                v-if="this.error.status"
                class="form-group mb-4">
                <div class="alert alert-danger" role="alert">
                  <strong>Error!</strong> {{ this.error.message }}
                </div>
              </div>
              <div v-show="success">
                <div class="form-group mb-4">
                  <label for="exampleInputEmail1" class="form-label">We've sent password reset instructions to your email.</label>
                </div>
                <button
                  type="submit"
                  class="btn btn-primary w-100">
                  <span
                    v-show="loading"
                    class="spinner-border spinner-border-sm">
                  </span>
                  <span
                    v-show="!loading">
                    Resend email
                  </span>
                </button>
              </div>
              <div v-show="!success">
                <div class="form-group mb-4">
                  <label for="exampleInputEmail1" class="form-label">Email</label>
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    v-model="input.email">
                </div>
                <button
                  type="submit"
                  class="btn btn-primary w-100">
                  <span
                    v-show="loading"
                    class="spinner-border spinner-border-sm">
                  </span>
                      <span
                        v-show="!loading">
                  Send password reset email
                  </span>
                </button>
              </div>
            </form>
          </div>

          <h5 class="m-0 mt-3">
            Never mind, I remember my password.
            <router-link
              :to="{ name: 'login' }"
              class="text-decoration-none forgot-anchor">
              Go Back
            </router-link>
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ForgetView',
  data () {
    return {
      loading: false,
      success: false,
      error: {
        status: false,
        message: null
      },
      input: {
        email: ''
      }
    }
  },
  methods: {
    submitForm () {
      this.loading = true

      if (this.input.email === '') {
        this.error.status = true
        this.error.message = 'Email address can not be empty'
        this.loading = false
      } else if (this.validateEmail(this.input.email)) {
        this.error.status = true
        this.error.message = 'Email address is not valid'
        this.loading = false
      } else {
        this.error.status = false
        this.error.message = null

        this.$store.dispatch('auth/forget', this.input).then(
          (res) => {
            this.loading = false
            this.success = true
          },
          (error) => {
            this.error.message = ''

            if (typeof error.errors !== 'undefined') {
              const errors = error.errors
              Object.keys(errors).forEach((errorKey) => {
                errors[errorKey].forEach((errVal, errIdx) => {
                  this.error.message += (this.error.message === '') ? errVal : ', ' + errVal
                })
              })
            } else if (typeof error.message !== 'undefined') {
              this.error.message = error.message
            }

            this.error.status = true
            this.loading = false
          }
        )
      }
    },
    validateEmail (email) {
      return !(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email))
    }
  }
}
</script>

<style scoped>
</style>
