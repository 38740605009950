import axios from 'axios'
import authHeader from '@/store/auth.header'

export const announcement = {
  namespaced: true,
  state: {
    feature: null
  },
  actions: {
    indexFeature ({ commit }) {
      return axios
        .get(process.env.VUE_APP_API_URL + 'announcement/feature', {
          headers: authHeader()
        })
        .then(res => {
          commit('populateFeature', res.data)
          console.log('topbar', res.data)
        })
        .catch(err => {
          return Promise.reject(err.response.data)
        })
    }
  },
  mutations: {
    populateFeature (state, data) {
      state.feature = data
    }
  }
}
