import axios from 'axios'
import authHeader from '@/config/authHeader.config'
import { apiState } from '@/config/apiState.config'
import { apiMutation } from '@/config/apiMutation.config'

const defaultModule = 'ticket'
const defaultState = {
  loading: {
    createRequests: 1,
    created: 0,
    index: false
  },
  status: [
    {
      id: 0,
      title: 'All',
      slug: 'all',
      value: null
    },
    {
      id: 1,
      title: 'Pending',
      slug: 'pending',
      value: 1
    },
    {
      id: 2,
      title: 'Answered',
      slug: 'answered',
      value: 2
    },
    {
      id: 3,
      title: 'Closed',
      slug: 'closed',
      value: 3
    }
  ],
  columns: [
    {
      label: 'Subject',
      name: 'subject'
    },
    {
      label: 'Department',
      name: 'department'
    },
    {
      label: 'Ticket Number',
      name: 'uid'
    },
    {
      label: 'Priority',
      name: 'priority'
    },
    {
      label: 'Submission Date',
      name: 'submission_date'
    },
    {
      label: 'Last Update',
      name: 'updated_at'
    },
    {
      width: '80px',
      label: 'Status',
      name: 'status'
    },
    {
      width: '80px',
      label: 'Action',
      name: 'action'
    }
  ]
}
const defaultMutation = {
}

export const ticket = {
  namespaced: true,
  state: JSON.parse(JSON.stringify({ ...apiState, ...defaultState })),
  actions: {
    created ({ state, dispatch, commit }) {
      if (state.rows === null) {
        commit('setRows', [])
        dispatch('index').then(() => {
          commit('incLoadingCreated')
        })
      }
    },
    index ({ state, commit }, url = process.env.VUE_APP_API_URL + defaultModule) {
      commit('setLoadingIndex', true)

      return axios
        .get(url, {
          headers: authHeader(),
          params: state.apiParams
        })
        .then(res => {
          commit('setRows', res.data.data)
          commit('configPagination', res.data)
          commit('setLoadingIndex', false)
          return Promise.resolve(res.data)
        })
        .catch(err => {
          commit('setLoadingIndex', false)
          return Promise.reject(err.response.data)
        })
    },
    store ({ commit }, data) {
      return axios
        .post(process.env.VUE_APP_API_URL + defaultModule, data, {
          headers: authHeader(true)
        })
        .then(res => {
          commit('storeRow', res.data)
          return Promise.resolve(res.data)
        })
        .catch(err => {
          return Promise.reject(err.response.data)
        })
    },
    stats ({ commit }) {
      return axios
        .get(process.env.VUE_APP_API_URL + defaultModule + '/stats', {
          headers: authHeader()
        })
        .then(res => {
          commit('setStats', res.data)
        })
        .catch(err => {
          return Promise.reject(err.response.data)
        })
    },
    setState ({ state, dispatch }, data) {
      console.log(data)
      if (data.loading !== undefined && data.loading.created !== undefined) {
        state.loading.created = data.loading.created
      }
      if (data.loading !== undefined && data.loading.index !== undefined) {
        state.loading.index = data.loading.index
      }
      if (data.searchBox !== undefined) {
        state.searchBox = data.searchBox
        if (state.searchBox === false && state.apiParams.search !== '') {
          state.apiParams.search = ''
          dispatch('index')
        }
      }
      if (data.search !== undefined && state.apiParams.search !== data.search) {
        state.apiParams.search = data.search
        dispatch('index')
      }
      if (data.status !== undefined) {
        state.apiParams.status = data.status
        dispatch('index')
      }
      return true
    },

    show ({ state, commit }, id) {
      return axios
        .get(process.env.VUE_APP_API_URL + 'ticket/' + id, {
          headers: authHeader()
        })
        .then(res => {
          return Promise.resolve(res.data)
        })
        .catch(err => {
          return Promise.reject(err.response)
        })
    },
    tableData ({ state }, data) {
      if (data.search !== undefined) {
        state.tableData.search = data.search
      }
      if (data.status !== undefined) {
        state.tableData.status = data.status
      }
      return true
    }
  },
  mutations: { ...apiMutation, ...defaultMutation }
}
