<template>
  <div class="password-reset-view">
    <div class="body-auth">
      <div class="box-window d-flex justify-content-center align-items-center w-100">
        <div>
          <div class="d-flex justify-content-center mb-4">
            <img src="@/assets/logo.svg" alt="Sixerr">
          </div>
          <div class="d-flex justify-content-center mb-4">
            <h1>Signing in to user account</h1>
          </div>

          <div class="box box-shadow-default">
            <div class="w-100 d-flex justify-content-center align-items-center">
              <span class="spinner-border spinner-border-sm">
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VerifyView',
  created () {
    console.log('this.$store.state.auth.authenticated', this.$store.state.auth.authenticated)
    if (this.$store.state.auth.authenticated) {
      this.$store.dispatch('auth/logout').then(() => {
        location.reload()
      })
    } else {
      this.$store.dispatch('auth/loginAsUser', { id: this.$route.params.id, token: this.$route.params.token }).then(() => {
        this.$store.dispatch('user/show', this.$route.params.id).then(() => {
          this.created++
        })
        this.$store.dispatch('category/index').then(() => {
          this.created++
        })
        this.$store.dispatch('service/index').then(() => {
          this.created++
        })
      })
    }
  },
  data () {
    return {
      created: 0,
      createRequests: 3
    }
  },
  watch: {
    created: function (newVal, oldVal) {
      if (newVal >= this.createRequests) {
        this.$router.push({ name: 'dashboard.index' })
      }
    }
  }
}
</script>

<style scoped>
</style>
