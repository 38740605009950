<template>
  <div class="row">
    <div
      v-for="item in data"
      :key="item.id"
      :class="getClass()">
      <div
        @click="$emit('module-click', item.id)"
        class="card border-0 mb-5 card-anchor">
        <div class="card-body">
          <h5 class="card-title">
            {{ item.value }}
          </h5>
        </div>
        <div class="card-header bg-transparent border-0">
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatsModule',
  props: ['data'],
  methods: {
    getClass () {
      switch (this.data.length) {
        case 1:
          return 'col-12'
        case 3:
          return 'col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 col-xxl-4'
        case 4:
          return 'col-12 col-sm-6 col-md-12 col-lg-6 col-xl-3 col-xxl-3'
      }
    }
  }
}
</script>

<style scoped>

</style>
