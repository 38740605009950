<template>
  <div
    v-if="announcementFeature"
    class="top-bar-view">
    <nav class="navbar navbar-light">
      <span class="navbar-text m-auto p-0">
        {{ announcementFeature.title }}
        <a
          href="#"
          class="navbar-text m-auto p-0"
          data-bs-toggle="modal"
          :data-bs-target="`#announcementFeature${announcementFeature.id}`">
          View Details
        </a>
      </span>
    </nav>

    <!-- Modal -->
    <div class="modal fade" :id="`announcementFeature${announcementFeature.id}`" tabindex="-1"
         aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <img
            class="modal-close"
            width="24"
            height="24"
            src="@/assets/ic-close.svg"
            data-bs-dismiss="modal"
            aria-label="Close">
          <h5 class="modal-title" id="staticBackdropLabel">{{ announcementFeature.title }}</h5>
          <div class="modal-body" v-html="announcementFeature.message">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopBar',
  computed: {
    announcementFeature () {
      return this.$store.state.announcement.feature
    }
  }
}
</script>

<style scoped>
.top-bar-view .navbar {
  background-color: #CCF1EA !important;
  z-index: 100;
}

.top-bar-view .navbar-text {
  color: #0A2540;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.modal-dialog {
  max-width: 640px;
}

.modal-close {
  margin: 16px 16px 22px 16px;
  cursor: pointer;
}

.modal-title {
  font-size: 16px;
  font-weight: bold;
  color: var(--text-title);
  margin: 0 42px 16px 42px;
}

.modal-body {
  font-size: 14px;
  line-height: 28px;
  font-weight: normal;
  color: var(--text-default);
  margin: 0 42px 51px 42px;
  padding: unset;
}
</style>
