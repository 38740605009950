<template>
  <button
    type="button"
    class="btn btn-status"
    :class="getObject(status).class">
    {{ this.getObject(status).name }}
  </button>
</template>

<script>
export default {
  name: 'StatusButton',
  props: {
    status: {
      type: String,
      required: true
    }
  },
  methods: {
    getObject (status) {
      switch (status) {
        case 'pending':
          return {
            name: 'Pending',
            class: 'btn-pending'
          }
        case 'inProgress':
          return {
            name: 'In Progress',
            class: 'btn-in-progress'
          }
        case 'completed':
          return {
            name: 'Completed',
            class: 'btn-completed'
          }
        case 'paid':
          return {
            name: 'Paid',
            class: 'btn-completed'
          }
        case 'cancelled':
          return {
            name: 'Cancelled',
            class: 'btn-canceled'
          }
        case 'answered':
          return {
            name: 'Answered',
            class: 'btn-answered'
          }
        case 'closed':
          return {
            name: 'Closed',
            class: 'btn-canceled'
          }
        case 'empty':
          return {
            name: 'Empty',
            class: 'btn-canceled'
          }

        case 'refunded':
          return {
            name: 'Refunded',
            class: 'btn-in-progress'
          }
        case 'failed':
          return {
            name: 'Failed',
            class: 'btn-canceled'
          }

        /* status */
        case 'enable':
          return {
            name: 'Publish',
            class: 'btn-completed'
          }
        case 'disable':
          return {
            name: 'Un-publish',
            class: 'btn-canceled'
          }

        /* priority */
        case 'low':
          return {
            name: 'Low',
            class: 'btn-low'
          }
        case 'medium':
          return {
            name: 'Medium',
            class: 'btn-medium'
          }
        case 'high':
          return {
            name: 'High',
            class: 'btn-high'
          }

        /* users */
        case 'verified':
          return {
            name: 'Verified',
            class: 'btn-completed'
          }
        case 'unVerified':
          return {
            name: 'Un-verified',
            class: 'btn-pending'
          }
        case 'suspended':
          return {
            name: 'Suspended',
            class: 'btn-in-progress'
          }
        case 'banned':
          return {
            name: 'Banned',
            class: 'btn-canceled'
          }
        default:
          return {
            name: 'Unknown',
            class: 'btn-canceled'
          }
      }
    }
  }
}
</script>

<style scoped>
.btn-status {
  font-size: 12px;
  font-weight: normal;
  color: white;
  border-radius: 4px;
  width: 80px;
  /*height: 18px;*/
  padding: unset;
}

.btn-status:hover {
  opacity: 0.9;
}

.btn-pending {
  background-color: #FFF8EC;
  color: #0A2540;
}

.btn-in-progress {
  background-color: #CCF1EA;
    color: #0A2540;
}

.btn-canceled,
.btn-rejected,
.btn-sent {
  background-color: #FFD6CB;
  color: #0A2540;
}

.btn-completed,
.btn-answered,
.btn-added {
  background-color: #E6F7FF;
  color: #0A2540;
  /*border-color: var(--green-default);*/
}

.btn-low {
  color: var(--green-default);
  font-weight: bold;
  /*border-color: var(--green-default);*/
}
.btn-medium {
  color: var(--purple);
  font-weight: bold;
  /*border-color: var(--purple);*/
}
.btn-high {
  color: var(--bg-red);
  font-weight: bold;
  /*border-color: var(--bg-red);*/
}
</style>
