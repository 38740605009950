export const apiMutation = {
  /* alerts */
  resetAlerts (state) {
    state.alert.status = false
    state.alert.type = 0
    state.alert.message = null
  },
  setDangerAlert (state, message) {
    state.alert.status = true
    state.alert.type = 'alert-danger'
    state.alert.message = message
    this.commit(state.namespace + '/triggerTimeoutAlert')
  },
  setSuccessAlert (state, message) {
    state.alert.status = true
    state.alert.type = 'alert-success'
    state.alert.message = message
    this.commit(state.namespace + '/triggerTimeoutAlert')
  },
  triggerTimeoutAlert (state, module) {
    clearTimeout(state.alert.timeout)
    state.alert.timeout = setTimeout(() => { this.commit(state.namespace + '/resetAlerts') }, 3000)
  },

  incLoadingCreated (state) {
    state.loading.created++
  },
  setLoadingIndex (state, value) {
    state.loading.index = value
  },
  configPagination (state, data) {
    state.pagination.lastPage = data.meta.last_page
    state.pagination.currentPage = data.meta.current_page
    state.pagination.total = data.meta.total
    state.pagination.lastPageUrl = data.links.last
    state.pagination.nextPageUrl = data.links.next
    state.pagination.prevPageUrl = data.links.prev
    state.pagination.from = data.meta.from
    state.pagination.to = data.meta.to
  },
  setRows (state, data) {
    state.rows = data
    console.log(state.namespace, state.rows)
  },
  storeRow (state, data) {
    if (state.loading.created >= state.loading.createRequests) {
      if (state.rows.length >= 10) {
        state.rows.pop()
      }
      state.rows.unshift(data)
    }
  },
  incStats (state, slug) {
    if (state.loading.created >= state.loading.createRequests) {
      state.stats.forEach((el, idx) => {
        if (el.slug === slug) {
          state.stats[idx].value++
        }
      })
    }
  }
}
