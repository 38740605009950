<template>
  <div class="header-view">
    <nav class="navbar off-navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">
        </a>

        <div class="d-flex align-items-center">

          <div class="btn-group me-4">
            <div
              class="order-btn-circle d-flex justify-content-center align-items-center"
              data-bs-toggle="dropdown"
              data-bs-display="static"
              aria-expanded="false">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                   stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <line x1="12" y1="5" x2="12" y2="19"></line>
                <line x1="5" y1="12" x2="19" y2="12"></line>
              </svg>
            </div>
            <ul class="dropdown-menu-profile dropdown-menu dropdown-menu-end p-3" style="width:unset">
              <li class="mb-2">
                <router-link
                  :to="{ name: 'order.store' }"
                  class="dropdown-item text-decoration-none d-flex justify-content-start align-items-center align-content-center"
                  aria-current="page">
                  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#D0CDFF"><path d="M520-400h80v-120h120v-80H600v-120h-80v120H400v80h120v120ZM320-240q-33 0-56.5-23.5T240-320v-480q0-33 23.5-56.5T320-880h480q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H320Zm0-80h480v-480H320v480ZM160-80q-33 0-56.5-23.5T80-160v-560h80v560h560v80H160Zm160-720v480-480Z"/></svg>
                  <span class="ps-3">New Order</span>
                </router-link>
              </li>
              <li class="mb-2">
                <router-link
                  :to="{ name: 'fund.index' }"
                  class="dropdown-item text-decoration-none d-flex justify-content-start align-items-center align-content-center"
                  aria-current="page">
                  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#D0CDFF"><path d="M880-720v480q0 33-23.5 56.5T800-160H160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720Zm-720 80h640v-80H160v80Zm0 160v240h640v-240H160Zm0 240v-480 480Z"/></svg>
                  <span class="ps-3">Add Funds</span>
                </router-link>
              </li>
              <li class="mb-2">
                <router-link
                  :to="{ name: 'ticket.store' }"
                  class="dropdown-item text-decoration-none d-flex justify-content-start align-items-center align-content-center"
                  aria-current="page">
                  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#D0CDFF"><path d="M480-680q17 0 28.5-11.5T520-720q0-17-11.5-28.5T480-760q-17 0-28.5 11.5T440-720q0 17 11.5 28.5T480-680Zm-40 320h80v-240h-80v240ZM80-80v-720q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H240L80-80Zm126-240h594v-480H160v525l46-45Zm-46 0v-480 480Z"/></svg>
                  <span class="ps-3">Open Ticket</span>
                </router-link>
              </li>
            </ul>
          </div>

          <button type="button" class="d-none d-md-block btn btn-outline-success me-4">
            {{ centsToDollar(currentUser.cash) }}
          </button>

          <div class="btn-group">
            <img
              v-if="currentUser.avatar"
              :src="currentUser.avatar.original_url"
              class="rounded-circle dropdown-toggle cursor-pointer"
              width="36"
              height="36"
              alt="user profile picture"
              loading="lazy"
              data-bs-toggle="dropdown"
              data-bs-display="static"
              aria-expanded="false"
            />
            <img
              v-else
              src="@/assets/favicon.svg"
              class="rounded-circle dropdown-toggle cursor-pointer"
              width="36"
              height="36"
              alt="user profile picture"
              loading="lazy"
              data-bs-toggle="dropdown"
              data-bs-display="static"
              aria-expanded="false"
            />
            <ul class="dropdown-menu-profile dropdown-menu dropdown-menu-end">
              <div class="dropdown-header-box p-3 pt-1">
                <li>
                  <h6 class="dropdown-header"></h6>
                  <div class="d-flex align-content-start">

                    <img
                      v-if="currentUser.avatar"
                      :src="currentUser.avatar.original_url"
                      class="rounded-circle dropdown-toggle cursor-pointer"
                      width="50"
                      height="50"
                      alt="user profile picture"
                      loading="lazy"
                      data-bs-toggle="dropdown"
                      data-bs-display="static"
                      aria-expanded="false"
                    />
                    <img
                      v-else
                      src="@/assets/favicon.svg"
                      class="rounded-circle dropdown-toggle"
                      width="50"
                      height="50"
                      alt="Black and White Portrait of a Man"
                      loading="lazy"
                      data-bs-toggle="dropdown"
                      data-bs-display="static"
                      aria-expanded="false"
                    />
                    <div class="ms-3">
                      <h2>{{ this.currentUser.name }}</h2>
                      <h5>{{ this.currentUser.email }}</h5>
                      <span class="cursor-pointer">Monthly Basic</span>
                    </div>
                  </div>
                </li>
              </div>

              <li>
                <router-link
                  :to="{ name: 'user.show' }"
                  class="dropdown-item text-decoration-none">
                  Profile
                </router-link>
              </li>
              <li>
                <router-link
                  to="/notification"
                  class="dropdown-item text-decoration-none"
                  v-slot="{ route }">
                  {{ route.name }}
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: 'user.billing' }"
                  class="dropdown-item text-decoration-none">
                  Billing
                </router-link>
              </li>
              <li><a class="dropdown-item" href="#">Terms & Policies</a></li>
              <li><a
                class="dropdown-item action"
                @click.prevent="logOut">
                Logout
              </a></li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'TopBar',
  created () {
  },
  methods: {
    logOut () {
      this.$store.dispatch('auth/logout').then(() => {
        this.$router.push({ name: 'login' })
        this.$router.go()
      })
    },

    /* Static global helpers */
    centsToDollar (cents) {
      return this.$centsToDollar(cents)
    }
  },
  computed: {
    currentUser () {
      return this.$store.state.user.row
    }
  }
}
</script>

<style scoped>
.navbar-text {
  color: var(--text-orange);
  font-size: 11px;
  font-weight: 600;
}

button {
  font-size: 16px;
  font-weight: 600;
  color: var(--green-default);
  border: 1px solid var(--green-default);
  padding: 0.075rem 0.75rem;
}

/*notify*/
.dropdown-menu {
  margin-top: 1.35rem;
  padding: unset;
}

.order-btn-circle {
  padding: 7px;
  border-radius: 50%;
  background-color: var(--purple-default);
  cursor: pointer;
}
</style>
