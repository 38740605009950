<template>
  <div class="subscription-view">

    <div class="vue-modal modal fade" id="subscribeModal" tabindex="-1"
         aria-labelledby="subscribeModal" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header p-3 border-0">
            <h5 class="modal-title">
              <div class="d-flex justify-content-start">
                <img
                  class="select-box-icon"
                  width="15"
                  src="@/assets/verified.svg">
                <div class="modal-title-main">Secured Checkout</div>
              </div>
              <p class="modal-title-sub">You are connected to a secure encrypted SSL server.</p>
            </h5>
            <h3 class="modal-title">{{ centsToDollar(currentUser.cash) }}</h3>
          </div>
          <div class="modal-body p-4">
            <h3 class="modal-heading mb-3 text-center">Select a payment method</h3>
            <div class="row">
              <div
                v-if="setting.length > 0 && parseInt(setting.find(el => el.name === 'stripe').value) !== 0"
                class="col-4 no-select">
                <div
                  @click="changeSelected('stripe')"
                  class="select-box text-center"
                  :class="isSelected('stripe')">
                  <img
                    class="select-box-icon"
                    width="60"
                    height="30"
                    src="@/assets/stripe.svg"
                    draggable="false">
                  <h3>Stripe</h3>
                </div>
              </div>
              <div
                v-if="setting.length > 0 && parseInt(setting.find(el => el.name === 'paypal').value) !== 0"
                class="col-4 no-select">
                <div
                  @click="changeSelected('paypal')"
                  class="select-box text-center"
                  :class="isSelected('paypal')">
                  <img
                    class="select-box-icon"
                    width="60"
                    height="30"
                    src="@/assets/paypal.svg"
                    draggable="false">
                  <h3>Paypal</h3>
                </div>
              </div>
              <div
                v-if="setting.length > 0 && parseInt(setting.find(el => el.name === 'credit_card').value) !== 0"
                class="col-4 no-select">
                <div
                  @click="changeSelected('card')"
                  class="select-box text-center"
                  :class="isSelected('card')">
                  <img
                    class="select-box-icon"
                    width="60"
                    height="30"
                    src="@/assets/master-card.svg"
                    draggable="false">
                  <h3>Credit Card</h3>
                </div>
              </div>
            </div>

            <div class="row mt-5">
              <div class="col-8 offset-2">
                <div
                  v-if="this.error.status"
                  class="form-group mb-4">
                  <div class="alert alert-danger" role="alert">
                    {{ this.error.message }}
                  </div>
                </div>
              </div>
            </div>
            <div
              v-show="isSelected('stripe')"
              class="row mt-5">
              <div class="col-8 offset-2">
                <div v-if="!newPaymentMethod">
                  <DebitCard :last4="paymentMethod.last_four" :name="paymentMethod.name" :brand="paymentMethod.brand" :expiry-month="paymentMethod.exp_month" :expiry-year="paymentMethod.exp_year" />

                  <div class="text-center mt-3">
                    <div class="d-flex justify-content-between">
                      <a
                        @click="newPaymentMethod = true"
                        class="text-decoration-none cursor-pointer ms-5">Replace Card</a>
                      <a
                        @click="removePaymentMethod"
                        class="text-decoration-none cursor-pointer me-5">
                        <span v-if="loading.pm.remove" class="spinner-border spinner-border-sm"></span>
                        <span v-else>Remove Card</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div v-show="newPaymentMethod">
                  <label for="card-holder-name" class="form-label">Card Holder Name</label>
                  <input id="card-holder-name" type="text" v-model="name" class="form-control mb-2">

                  <label for="card" class="form-label">Card</label>
                  <div id="card-element">
                  </div>
                  <div
                    v-if="paymentMethod !== null"
                    class="mt-4">
                    <a
                      @click="newPaymentMethod = false"
                      class="text-decoration-none cursor-pointer">Use saved card</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer p-3 border-0">
            <button type="button" class="modal-close" data-bs-dismiss="modal">Close</button>
            <button
              @click.prevent="checkout"
              type="button"
              class="btn modal-submit">
              <span v-if="loading.checkout" class="spinner-border spinner-border-sm"></span>
              <span v-else>Subscribe</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h1 class="view-name">Howdy!</h1>
      </div>
    </div>

    <StatsModule
      v-if="stats"
      :data="stats"/>
    <div class="row">
      <div class="col-12">
        <div class="card border-0 mb-3">
          <div class="card-header card-header-custom bg-transparent border-0 bg_clean">Choose Your Plan</div>
          <div class="card-header card-header-sub bg-transparent border-0">Subscribe to become a member and enjoy a flat discount on all services, priority support, and faster order processing.
          </div>
          <div class="card-body pt-4 pb-4">
            <div class="row">
              <div
                v-for="row in rows"
                :key="row.id"
                class="col-12 mb-3 col-lg-4 mb-lg-0">
                <div class="card card-membership border-0">
                  <div class="card-body pt-20">
                    <h5 class="title">{{ row.name }}</h5>
                    <h5 class="price mb-4">{{ row.price_display }} / {{ row.abbriviation }}</h5>
                    <h5 class="info mb-4">{{ row.description }}</h5>
                    <h5 class="discount">{{ row.discount }}%</h5>
                    <h5 class="discount-info ms-2">Flat Discount</h5>
                    <a
                      v-if="row.user_subscription === null || row.user_subscription.ends_at !== null"
                      v-on:click="selectedRowId = row.id | this.subscribeModal.show()"
                      class="btn btn-custom btn-order p-3 w-100 mt-5">
                      Subscribe
                    </a>
                    <a
                      v-if="row.user_subscription !== null && row.user_subscription.ends_at === null"
                      v-on:click="subscriptionCancel"
                      class="btn btn-custom btn-order p-3 w-100 mt-5 btn-cancel">
                      <span v-if="loading.cancel" class="spinner-border spinner-border-sm"></span>
                      <span v-else>Cancel Subscription</span>
                    </a>

                    <h5
                      v-if="row.user_subscription !== null && row.user_subscription.ends_at !== null"
                      class="discount-info mt-3 text-center">cancels {{ timestampToDate(row.user_subscription.ends_at) }}</h5>
                    <h5
                      v-if="row.user_subscription !== null && row.user_subscription.ends_at === null"
                      class="discount-info mt-3 text-center">renews at {{ timestampToDate(row.user_subscription.renews_at) }}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from 'bootstrap/js/dist/modal'
import { loadStripe } from '@stripe/stripe-js'
import DebitCard from '@/components/Stripe/DebitCard'
import StatsModule from '@/components/Modules/StatsModule'
import axios from 'axios'
import authHeader from '@/store/auth.header'
import { centsToDollar } from '@/config/globalMethods.config'

export default {
  name: 'SubscriptionView',
  components: {
    StatsModule,
    DebitCard
  },
  created () {
    if (this.setting.length > 0 && parseInt(this.setting.find(el => el.name === 'stripe').value) !== 0) {
      this.selected = 'stripe'
    }

    this.dispatchRow()
  },
  data () {
    return {
      stats: null,

      subscribeModal: null,

      created: false,
      loading: {
        created: false,
        row: false,
        checkout: false,
        cancel: false,
        pm: {
          remove: false
        }
      },
      rows: [],
      paymentMethod: null,
      selectedRowId: null,
      error: {
        status: false,
        message: null
      },

      selected: null,
      newPaymentMethod: true,

      stripeAPIToken: 'pk_test_51K9SnaH19zFd8WybfzEAhXyZkE8P2TQWd0aWQYkg1tKm5WO1y1i3bs4ImjFmhkAX16cxG1laBPd3ijU2zezV0eO200SCl4Y4TT',
      stripe: '',
      elements: '',
      name: '',
      card: '',
      intentToken: ''
    }
  },
  methods: {
    dispatchRow () {
      axios
        .get(process.env.VUE_APP_API_URL + 'subscription/stats', {
          headers: authHeader()
        })
        .then(res => {
          this.stats = [
            {
              id: 1,
              title: 'Active Discount',
              slug: 'subscription',
              value: res.data.membership + '%',
              info: 'active discount',
              svg: 'membershipSubscription'
            },
            {
              id: 2,
              title: 'Total Saved',
              slug: 'savings',
              value: centsToDollar(res.data.savings),
              info: 'total saved',
              svg: 'membershipSavings'
            },
            {
              id: 3,
              title: 'Joined',
              slug: 'joined',
              value: this.timestampToDate(res.data.joined),
              info: 'member since',
              svg: 'membershipJoined'
            }
          ]
          console.log('dispatchRow-subscription-stats', res.data)
          return Promise.resolve(res.data)
        })
        .catch(err => {
          return Promise.reject(err.response.data)
        })

      this.$store.dispatch('plan/index').then((res) => {
        this.loading.created = true
        this.loading.checkout = false
        this.loading.cancel = false
        this.rows = res

        if (this.subscribeModal._isShown) {
          this.subscribeModal.hide()
        }
        console.log(res)
      })
      this.$store.dispatch('stripe/indexPaymentMethod').then((res) => {
        if (res !== false) {
          this.paymentMethod = res
          this.newPaymentMethod = false
          console.log('stripe/indexPaymentMethod', typeof res, res)
        }
      })
    },
    subscriptionCancel () {
      this.loading.cancel = true
      this.$store.dispatch('stripe/subscriptionCancel').then((res) => {
        this.dispatchRow()
      })
    },
    subscriptionUpdate (paymentMethodId) {
      this.$store.dispatch('stripe/subscriptionUpdate', {
        _method: 'put',
        planID: this.selectedRowId,
        paymentMethodID: paymentMethodId
      }).then((res) => {
        this.name = ''
        this.card.clear()
        this.dispatchRow()
      }).catch((err) => {
        this.alertError(err.message !== undefined ? err.message : err.error)
      })
    },
    alertError (message) {
      this.error.status = true
      this.error.message = message
      this.loading.checkout = false
    },

    /*
      Stripe
    */
    async configureStripe () {
      this.stripe = await loadStripe(this.stripeAPIToken)

      this.elements = this.stripe.elements()
      this.card = this.elements.create('card', {
        hidePostalCode: true,
        style: {
          base: {
            iconColor: '#666EE8',
            color: '#31325F',
            lineHeight: '40px',
            fontWeight: 300,
            fontSize: '15px',

            '::placeholder': {
              color: '#CFD7E0'
            }
          }
        }
      })

      this.card.mount('#card-element')
    },

    changeSelected (selected) {
      this.selected = selected
    },
    isSelected (selected) {
      return (this.selected === selected) ? 'selected' : null
    },
    removePaymentMethod () {
      this.loading.pm.remove = true

      this.$store.dispatch('stripe/removePaymentMethod').then((res) => {
        if (res !== false) {
          this.paymentMethod = null
          this.newPaymentMethod = true
        }
        this.loading.pm.remove = false
      })
    },

    checkout () {
      this.error.status = false
      this.error.message = ''
      this.loading.checkout = true

      if (!this.selected) {
        return this.alertError('Select a payment method.')
      }

      /* (server) create intent */
      this.$store.dispatch('stripe/createIntent').then((res) => {
        console.log('createIntent ()', res)
        this.intentToken = res

        if (this.newPaymentMethod === false && this.paymentMethod !== null) {
          /* (server) update or create subscription */
          this.subscriptionUpdate(this.paymentMethod.id)
        } else {
          if (this.name === '') {
            return this.alertError('card holder name is a required field.')
          }

          /* (client) create payment method */
          this.stripe.confirmCardSetup(
            this.intentToken.client_secret, {
              payment_method: {
                card: this.card,
                billing_details: {
                  name: this.name
                }
              }
            }
          ).then(function (res) {
            if (res.error) {
              this.alertError(res.error.message)
              console.log(res.error)
            } else {
              console.log('createPaymentMethod() result', res)

              /* (server) update or create subscription */
              this.subscriptionUpdate(res.setupIntent.payment_method)
            }
          }.bind(this))
        }
      })
    },

    /* Static global helpers */
    timestampToDate (timestamp) {
      return this.$timestampToDate(timestamp)
    },
    centsToDollar (cents) {
      return this.$centsToDollar(cents)
    }
  },
  mounted () {
    this.subscribeModal = new Modal(document.getElementById('subscribeModal'))
    this.configureStripe()
  },
  computed: {
    currentUser () { return this.$store.state.user.row },
    setting () { return this.$store.state.setting.rows },
    activeSubscription () {
      return this.rows
        ? this.rows.find(row => {
          return row.user_subscription !== null
        })
        : null
    }
  }
}
</script>

<style scoped>
.card-membership {
  background-color: var(--bg-body) !important;
  border: 3px solid var(--border-default) !important;
}

.card-membership:hover {
  background-color: var(--white) !important;
  box-shadow: 0 30px 100px rgb(0 44 63 / 10%);
}

.title {
  font-size: 18px;
  font-weight: bold;
  color: var(--text-title);
}

.price {
  font-size: 14px;
  font-weight: bold;
  color: var(--text-default);
}

.info {
  font-size: 14px;
  font-weight: normal;
  color: var(--text-default);
  line-height: 22px;
}

.discount {
  font-size: 60px;
  font-weight: bold;
  color: var(--green-default);
}

.discount-info {
  font-size: 12px;
  font-weight: normal;
  color: var(--text-default);
}

.btn-custom {
  font-size: 16px;
  text-transform: uppercase;
  padding: 8px 16px !important;
}

</style>

<style scoped>
.modal-content {
  background-color: var(--bg-body);
}

.modal-dialog {
  max-width: 640px;
}

.modal-header {
  background-color: var(--white);
}

.modal-close {
  margin: 16px 0 0 16px;
  cursor: pointer;
}

.modal-title {
  font-size: 16px;
  font-weight: bold;
  color: var(--green-default);
  /*margin: 0 42px 0 42px;*/
}

.modal-title .modal-title-main {
  margin-left: 7px;
  font-size: 16px;
  font-weight: bold;
  color: var(--text-title);
}

.modal-title .modal-title-sub {
  /*margin-left: 27px;*/
  font-size: 11px;
  font-weight: normal;
  color: var(--text-default);
  margin-bottom: unset;
  margin-top: 2px;
}

.modal-body {
  font-size: 12px;
  font-weight: normal;
  color: var(--text-default);
  /*margin: 20px 42px 51px 42px;*/
  /*padding: unset;*/
}

.modal-body .modal-heading {
  font-size: 14px;
  font-weight: normal;
  color: var(--text-title);
  margin: 0 0 10px 0;
}

.select-box {
  border: 3px solid var(--border-default);
  border-radius: 6px;
  position: relative;
  padding: 10px;
  color: var(--text-default);
  /*background-color: var(--white);*/
  cursor: pointer;
}

.select-box:hover {
  background-color: var(--white);
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
}

h3 {
  font-size: 13px;
  margin: unset;
}

.select-box.selected {
  background-color: var(--white);
  border-color: var(--purple-default);
  color: var(--text-default);
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
}

.btn-order {
  font-size: 16px;
  width: 100px;
}

.modal-close {
  font-size: 14px;
  color: var(--text-default);
  cursor: pointer;
  text-decoration: none;
  padding: 10px;
  border: unset;
  margin: unset;
  background-color: unset;
}
</style>
