import axios from 'axios'
import authHeader from '@/store/auth.header'
import { apiState } from '@/config/apiState.config'
import { apiMutation } from '@/config/apiMutation.config'
import { centsToDollar } from '@/config/globalMethods.config'

const defaultModule = 'affiliate'
const defaultState = {
  loading: {
    createRequests: 2,
    created: 0,
    index: false
  },
  columns: [
    {
      label: 'Month',
      name: 'month'
    },
    {
      label: 'Clicks',
      name: 'clicks'
    },
    {
      label: 'Registered',
      name: 'registered'
    },
    {
      label: 'Commission',
      name: 'commission'
    },
    {
      width: '80px',
      label: 'Status',
      name: 'status'
    }
  ]
}
const defaultMutation = {
  setStats (state, data) {
    state.stats = [
      {
        id: 1,
        title: 'Users Referred',
        slug: 'registered',
        value: data.registered,
        info: 'total signed up',
        svg: 'affiliateUsers'
      },
      {
        id: null,
        title: 'Total Earned',
        slug: 'commission',
        value: centsToDollar(data.commission),
        info: 'total earned',
        svg: 'affiliateCommission'
      },
      {
        id: null,
        title: 'Withdrawn',
        slug: 'revenue',
        value: centsToDollar(data.revenue),
        info: 'total revenue',
        svg: 'affiliateWithdrawn'
      }
    ]
    console.log(state.stats)
  }
}

export const affiliate = {
  namespaced: true,
  state: JSON.parse(JSON.stringify({ ...apiState, ...defaultState })),
  actions: {
    created ({ state, dispatch, commit }) {
      if (state.rows === null) {
        commit('setRows', [])
        dispatch('index').then(() => {
          commit('incLoadingCreated')
        })
      }
      if (state.stats === null) {
        dispatch('stats').then(() => {
          commit('incLoadingCreated')
        })
      }
    },
    index ({ state, commit }, url = process.env.VUE_APP_API_URL + defaultModule) {
      commit('setLoadingIndex', true)

      return axios
        .get(url, {
          headers: authHeader(),
          params: state.apiParams
        })
        .then(res => {
          commit('setRows', res.data)
          commit('setLoadingIndex', false)
          return Promise.resolve(res.data)
        })
        .catch(err => {
          commit('setLoadingIndex', false)
          return Promise.reject(err.response.data)
        })
    },
    stats ({ commit }) {
      return axios
        .get(process.env.VUE_APP_API_URL + defaultModule + '/stats', {
          headers: authHeader()
        })
        .then(res => {
          commit('setStats', res.data)
        })
        .catch(err => {
          return Promise.reject(err.response.data)
        })
    }
  },
  mutations: { ...apiMutation, ...defaultMutation }
}
