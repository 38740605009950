<template>
  <div
    v-if="created.module < created.total"
    class="vw-100 vh-100 d-flex justify-content-center align-items-center">
    <span class="spinner-border spinner-border">
    </span>
  </div>
  <div v-else>
    <div
      class="auth-view"
      v-if="$route.name === 'login.as.user'">
      <router-view/>
      <FooterAuthComponent/>
    </div>
    <div
      class="auth-view"
      v-else-if="$route.name === 'auth.verify'">
      <router-view/>
      <FooterAuthComponent/>
    </div>
    <div
      class="auth-view"
      v-else-if="isPublicPage">
      <router-view/>
      <FooterAuthComponent/>
    </div>
    <div
      v-else-if="!isPublicPage">
      <TopBar/>
      <Header/>
      <Navigation/>
      <div class="main p-3 p-md-5">
        <VerifyAuth v-if="(currentUser.status === 'unVerified' || currentUser.status === 'suspended') && $route.name !== 'ticket.index' && $route.name !== 'ticket.store' && $route.name !== 'ticket.show'" />
        <router-view v-else/>
      </div>
      <FooterComponent/>
    </div>
  </div>
</template>

<script>
import TopBar from '@/components/TopBar.vue'
import Header from '@/components/Header.vue'
import FooterComponent from '@/components/FooterComponent.vue'
import FooterAuthComponent from '@/components/FooterAuthComponent.vue'
import Navigation from '@/components/Navigation.vue'
import VerifyAuth from '@/components/Auth/VerifyAuth'
import { publicRoutes } from '@/config/globalMethods.config'

export default {
  name: 'App',
  components: {
    VerifyAuth,
    TopBar,
    Header,
    FooterComponent,
    FooterAuthComponent,
    Navigation
  },
  created () {
    if (this.$store.state.auth.authenticated) {
      this.$store.dispatch('user/show', this.$store.state.auth.user.id).then((res) => {
        if (res.status === 'banned') {
          this.$store.dispatch('auth/logout').then(() => {
            this.$router.push({ name: 'login' })
            this.$router.go()
          })
        } else {
          this.created.module++
        }
      }).catch((err) => {
        if (err.status === 401) {
          this.$store.dispatch('auth/logout').then(() => {
            this.$router.push({ name: 'login' })
            this.$router.go()
          })
        }
      })

      this.$store.dispatch('setting/index').then(() => {
        this.created.module++
      })

      this.$store.dispatch('category/index')
      this.$store.dispatch('service/index')
      this.$store.dispatch('coupon/index')
      this.$store.dispatch('announcement/indexFeature')
    } else {
      this.created.module = this.created.total
    }
  },
  data () {
    return {
      created: {
        total: 2,
        module: 0
      }
    }
  },
  mounted () {
  },
  computed: {
    isAuthenticated () {
      return this.$store.state.auth.authenticated
    },
    authUser () {
      return this.$store.state.auth.user
    },
    currentUser () {
      return this.$store.state.user.row
    },
    isPublicPage () {
      console.log(this.$route.name)
      return this.$route.name === undefined ? true : publicRoutes.includes(this.$route.name)
    }
  }
}
</script>

<style>
</style>

<style>

.auth-view .body-auth {
    padding: 0px 30px;
}
.auth-view .box-window {
    max-width: 380px;
    margin: auto;
}

.auth-view img {
  width: 90px;

}

.auth-view h1 {
  font-size: 22px;
  font-weight: 900;
  color: #0A2540;
  text-align:center;
}

.auth-view h5 {
  font-size: 14px;
  font-weight: normal;
  color: var(--text-default);
  text-align: center;
}

.auth-view .box {
    padding: 30px 40px;
    border: 1px solid var(--border-default);
    background: linear-gradient(180deg, #f8f8f8 0%, #fff 100%);
    min-width: 380px;
    border-radius: 10px;
    border-bottom: 3px solid #dde3ff;
}

.auth-view form {
    margin-top: 40px;
}

.auth-view .form-label {
    font-size: 15px;
    color: var(--text-title);
    margin: unset;
    font-weight: normal;
    margin-bottom:4px;
}

.auth-view .forgot-anchor {
  font-size: 14px;
  font-weight: normal;
  color: var(--purple-default);
}

.auth-view .form-check-label {
  font-size: 14px;
  font-weight: normal;
  color: var(--text-default);
}

.auth-view input[type=text],
.auth-view input[type=email],
.auth-view input[type=password] {
  font-size: 14px;
  font-weight: normal;
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid var(--border-default);
  color: var(--text-default);
}

.auth-view .btn {
  font-size: 16px;
  font-weight: 500;
  border: 1px solid var(--border-default);
  border-radius: 6px;
  background-color: var(--purple-default);
  padding:10px;
}
</style>
