<template>
  <div class="password-reset-view">
    <div class="body-auth">
      <div class="box-window d-flex justify-content-center align-items-center w-100">
        <div>
          <div class="d-flex justify-content-center mb-4">
            <img src="@/assets/logo.svg" alt="Sixerr">
          </div>
          <div class="d-flex justify-content-center mb-4">
            <h1>Reset password</h1>
          </div>

          <div class="box box-shadow-default">
            <form @submit.prevent="submitForm">
              <div
                v-if="this.error.status"
                class="form-group mb-4">
                <div class="alert alert-danger" role="alert">
                  <strong>Error!</strong> {{ this.error.message }}
                </div>
              </div>
              <div v-show="success">
                <div class="form-group mb-4">
                  <label for="exampleInputEmail1" class="form-label">Password changed successfully.</label>
                </div>
                <router-link
                  :to="{ name: 'login' }"
                  class="btn btn-primary w-100">
                  Login
                </router-link>
              </div>
              <div v-show="!success">
                <div class="form-group mb-4">
                  <label for="token" class="form-label">Token</label>
                  <input
                    type="text"
                    class="form-control"
                    id="token"
                    readonly="readonly"
                    v-model="input.token">
                </div>
                <div class="form-group mb-4">
                  <label for="password" class="form-label">New Password</label>
                  <input
                    type="password"
                    class="form-control"
                    id="password"
                    v-model="input.password">
                </div>
                <div class="form-group mb-4">
                  <label for="password_confirmation" class="form-label">Confirm Password</label>
                  <input
                    type="password"
                    class="form-control"
                    id="password_confirmation"
                    v-model="input.password_confirmation">
                </div>
                <button
                  type="submit"
                  class="btn btn-primary w-100">
                  <span
                    v-show="loading"
                    class="spinner-border spinner-border-sm">
                  </span>
                      <span
                        v-show="!loading">
                    Reset Password
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResetView',
  created () {
    if (this.$store.state.auth.authenticated) {
      console.log('user is logged in')
    } else {
      console.log('user is not logged in')
    }
  },
  data () {
    return {
      loading: false,
      success: false,
      error: {
        status: false,
        message: null
      },
      input: {
        token: this.$route.params.token,
        password: '',
        password_confirmation: ''
      }
    }
  },
  methods: {
    submitForm () {
      this.loading = true

      if (this.input.token === '') {
        this.error.status = true
        this.error.message = 'Token can not be empty'
        this.loading = false
      } else if (this.input.password === '') {
        this.error.status = true
        this.error.message = 'Password can not be empty'
        this.loading = false
      } else if (this.input.password_confirmation === '') {
        this.error.status = true
        this.error.message = 'Password confirmation can not be empty'
        this.loading = false
      } else {
        this.error.status = false
        this.error.message = null

        this.$store.dispatch('auth/reset', this.input).then(
          (res) => {
            this.loading = false
            this.success = true
          },
          (error) => {
            this.error.message = ''

            if (typeof error.errors !== 'undefined') {
              const errors = error.errors
              Object.keys(errors).forEach((errorKey) => {
                errors[errorKey].forEach((errVal, errIdx) => {
                  this.error.message += (this.error.message === '') ? errVal : ', ' + errVal
                })
              })
            } else if (typeof error.message !== 'undefined') {
              this.error.message = error.message
            }

            this.error.status = true
            this.loading = false
          }
        )
      }
    },
    validateEmail (email) {
      return !(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email))
    }
  }
}
</script>

<style scoped>
</style>
