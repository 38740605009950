import axios from 'axios'
import authHeader from '@/store/auth.header'

export const ticketReply = {
  namespaced: true,
  state: {
  },
  actions: {
    store ({ commit }, { parentID, data }) {
      console.log('data', data)
      return axios
        .post(`${process.env.VUE_APP_API_URL}ticket/${parentID}/reply`, data, {
          headers: authHeader(true)
        })
        .then(res => {
          return Promise.resolve(res)
        })
        .catch(err => {
          return Promise.reject(err.response.data)
        })
    }
  },
  mutations: {
  }
}
