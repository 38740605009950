<template>
  <div class="profile-view">
    <div class="card border-0 mb-3">

      <HeaderSettings />

      <div class="card-body">
        <div
          v-if="!created"
          class="d-flex justify-content-center">
          <span class="spinner-border spinner-border"></span>
        </div>
        <div v-else>
          <div class="row">
            <div class="col-12">
              <div
                v-if="error.status"
                class="form-group mb-4">
                <div class="alert alert-danger" role="alert">
                  {{ error.message }}
                </div>
              </div>
            </div>
            <div class="col-12">

              <div v-if="paymentMethod && !add">
                <table class="table h-100 m-0">
                  <tr>
                    <th>Name</th>
                    <td>{{ paymentMethod.name }}</td>
                  </tr>
                  <tr>
                    <th>Number</th>
                    <td>**** **** **** {{ paymentMethod.last_four }}</td>
                  </tr>
                  <tr>
                    <th>Brand</th>
                    <td>{{ paymentMethod.brand }}</td>
                  </tr>
                  <tr>
                    <th>Expire</th>
                    <td>{{ paymentMethod.exp_month }}/{{ paymentMethod.exp_year }}</td>
                  </tr>
                </table>
                <div
                  v-if="paymentMethod"
                  class="d-flex justify-content-end">
                  <button
                    @click="removePaymentMethod"
                    type="button"
                    class="btn btn-custom btn-cancel me-3">
                    <span v-if="loading.remove" class="spinner-border spinner-border-sm"></span>
                    <span v-else>Remove Card</span>
                  </button>
                  <button
                    @click="add = true"
                    type="button"
                    class="btn btn-custom btn-action">
                    Replace Card
                  </button>
                </div>
              </div>
              <div v-else>
                <div class="form-group mb-4">
                  <SimpleInput
                    v-model="input.name"
                    type="text"
                    label="Card Holder Name"
                    name="name"
                  />
                </div>

                <label for="card" class="form-label">Card</label>
                <div id="card-element">
                </div>
                <div
                  class="d-flex justify-content-end mt-3">
                  <button
                    v-if="paymentMethod"
                    @click="add = false"
                    type="button"
                    class="btn btn-custom btn-cancel me-3">
                    Back
                  </button>
                  <button
                    @click="addPaymentMethod"
                    class="btn btn-custom btn-action">
                  <span
                    v-show="loading.add"
                    class="spinner-border spinner-border-sm">
                  </span>
                    <span
                      v-show="!loading.add">
                    Save
                  </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderSettings from '@/components/HeaderSettings'
import SimpleInput from '@/components/Form/SimpleInput'
import { loadStripe } from '@stripe/stripe-js'

export default {
  name: 'BillingView',
  components: {
    SimpleInput,
    HeaderSettings
  },
  created () {
    this.indexPaymentMethod()
  },
  data () {
    return {
      created: false,
      add: false,
      loading: {
        add: false,
        remove: false
      },
      error: {
        status: false,
        message: null
      },
      input: {
        name: ''
      },

      paymentMethod: null,

      /*
        Stripe
      */
      stripeAPIToken: 'pk_test_51K9SnaH19zFd8WybfzEAhXyZkE8P2TQWd0aWQYkg1tKm5WO1y1i3bs4ImjFmhkAX16cxG1laBPd3ijU2zezV0eO200SCl4Y4TT',
      stripe: '',
      elements: '',
      card: '',
      intentToken: ''
    }
  },
  methods: {
    /*
      Stripe
    */
    async configureStripe () {
      this.stripe = await loadStripe(this.stripeAPIToken)

      this.elements = this.stripe.elements()
      this.card = this.elements.create('card', {
        hidePostalCode: true,
        style: {
          base: {
            iconColor: '#666EE8',
            color: '#31325F',
            lineHeight: '40px',
            fontWeight: 300,
            fontSize: '15px',

            '::placeholder': {
              color: '#CFD7E0'
            }
          }
        }
      })

      this.card.mount('#card-element')
    },

    /*
      methods
    */
    indexPaymentMethod () {
      this.$store.dispatch('stripe/indexPaymentMethod').then((res) => {
        if (res !== false) {
          this.paymentMethod = res
          console.log('stripe/indexPaymentMethod', res)
        } else if (this.created === false) {
          this.configureStripe()
        }

        this.created = true
        this.add = false
        this.loading.add = false
      })
    },
    addPaymentMethod () {
      this.error.status = false
      this.loading.add = true

      if (this.input.name === '') {
        this.alertError('Name is a required field')
        return
      }

      this.$store.dispatch('stripe/createIntent').then((res) => {
        console.log('createIntent ()', res)
        this.intentToken = res

        /* (client) create payment method */
        this.stripe.confirmCardSetup(
          this.intentToken.client_secret, {
            payment_method: {
              card: this.card,
              billing_details: {
                name: this.input.name
              }
            }
          }
        ).then(function (res) {
          if (res.error) {
            this.alertError(res.error.message)
            console.log(res.error)
          } else {
            console.log('createPaymentMethod() result', res)
            this.$store.dispatch('stripe/createPaymentMethod', {
              payment_method: res.setupIntent.payment_method
            }).then((res) => {
              this.indexPaymentMethod()
            })
          }
        }.bind(this))
      })
    },
    removePaymentMethod () {
      this.loading.remove = true

      this.$store.dispatch('stripe/removePaymentMethod').then((res) => {
        if (res !== false) {
          this.paymentMethod = null
          this.add = true
        }
        this.loading.remove = false
      })
    },

    /*
      helpers
    */
    alertError (message) {
      this.error.status = true
      this.error.message = message
      this.loading.add = false
    }
  },
  computed: {
    currentUser () {
      return this.$store.state.user.row
    }
  },
  watch: {
    add: function (newVal, oldVal) {
      if (newVal === true) {
        this.configureStripe()
      } else {
        this.stripe = ''
        this.elements = ''
        this.card = ''
        this.intentToken = ''
      }
    }
  }
}
</script>

<style scoped>
tr th {
  width: 150px;
}
tr:hover th,
tr:hover td {
  background-color: unset;
  border-color: var(--hover-border-default);
}
.edit-picture {
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  width: 220px;
  height: 220px;
  border-radius: 50%;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
input {
  border: unset;
  padding: unset;
  height: 100%;
  width: 100%;
}
</style>
