<template>
  <footer class="footer_global">
    <div class="col-md-12">
      <span class="text-muted">© {{ currentYear }}, Sixerr.com - All Rights Reserved.</span>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterAuthComponent',
  data () {
    return {
      currentYear: new Date().getFullYear()
    }
  }
}
</script>

<style scoped>

.text-muted {
  color: var(--text-default)!important;
  font-size: 12px;
}
.external-link:hover {
  color: var(--text-title)!important;
}

</style>
