<template>
  <div
    v-if="loading.created < loading.createRequests"
    class="main-loading">
    <div class="spinner-border" role="status">
    </div>
  </div>
  <div v-else>
    <TitleModule :title="$options.title" />
    <StatsModule
      v-if="stats"
      :data="stats"
      @moduleClick="(val) => { dispatchChangeView(val) }"/>

    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-8">
        <div class="card border-0 mb-3">
          <div class="card-header bg-transparent border-0 bg_clean">
            <div class="d-flex justify-content-between">
              <div class="card-header-title card-header-custom">Recent Activity</div>
              <div class="card-header-button">
                <span @click="dispatchChangeTab('orders')" :class="activeRecentTab === 'orders' && 'active'">Orders</span>
                <span @click="dispatchChangeTab('funds')" :class="activeRecentTab === 'funds' && 'active'">Funds</span>
                <span @click="dispatchChangeTab('tickets')" :class="activeRecentTab === 'tickets' && 'active'">Tickets</span>
              </div>
            </div>
          </div>
          <div class="card-body">
            <RecentOrders v-if="activeRecentTab === 'orders'" :rows="orders"/>
            <RecentFunds v-if="activeRecentTab === 'funds'" :rows="funds"/>
            <RecentTickets v-if="activeRecentTab === 'tickets'" :rows="tickets"/>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4">
        <div class="card border-0 mb-3">
          <div class="card-header card-header-custom bg-transparent border-0 bg_clean">Announcements</div>
          <div class="card-body pt-4 pb-4">
            <div
              v-if="announcements.length > 0">
              <RecentAnnouncement
                v-for="row in announcements"
                :key="row.id"
                :id="row.id"
                :title="row.title"
                :message="row.message"/>
            </div>
            <div
              v-else
              class="d-flex justify-content-center align-items-center" style="min-height: 431px;">
              <h5>No data found</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RecentOrders from '@/components/Dashboard/RecentOrders.vue'
import RecentFunds from '@/components/Dashboard/RecentFunds.vue'
import RecentTickets from '@/components/Dashboard/RecentTickets.vue'
import RecentAnnouncement from '@/components/Dashboard/RecentAnnouncement.vue'
import TitleModule from '@/components/Modules/TitleModule'
import StatsModule from '@/components/Modules/StatsModule'

export default {
  title: 'Dashboard',
  name: 'DashboardView',
  components: {
    StatsModule,
    TitleModule,
    RecentOrders,
    RecentFunds,
    RecentTickets,
    RecentAnnouncement
  },
  created () {
    this.$store.dispatch('dashboard/created')
  },
  methods: {
    dispatchChangeTab (tab = null) { this.$store.dispatch('dashboard/setRecentTab', tab) },
    dispatchChangeView (tab = null) {
      switch (tab) {
        case 1:
          this.$router.push({ name: 'order.index' })
          break
        case 2:
          this.$router.push({ name: 'fund.index' })
          break
        case 3:
          this.$router.push({ name: 'ticket.index' })
          break
        case 4:
          this.$router.push({ name: 'subscription.index' })
          break
      }
    }
  },
  computed: {
    loading () { return this.$store.state.dashboard.loading },
    stats () { return this.$store.state.dashboard.stats },
    orders () { return this.$store.state.dashboard.recent.orders },
    funds () { return this.$store.state.dashboard.recent.funds },
    tickets () { return this.$store.state.dashboard.recent.tickets },
    announcements () { return this.$store.state.dashboard.announcements },
    activeRecentTab () { return this.$store.state.dashboard.recent.tab }
  }
}
</script>

<style scoped>
</style>
