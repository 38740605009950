import axios from 'axios'
import authHeader from '@/store/auth.header'

export const categoryService = {
  namespaced: true,
  state: {
    parentID: null,
    item: null,
    index: []
  },
  actions: {
    index ({ commit }, parentID) {
      return axios
        .get(`${process.env.VUE_APP_API_URL}category/${parentID}/service`, {
          headers: authHeader()
        })
        .then(res => {
          commit('selectParent', parentID)
          commit('populateIndex', res.data)
          commit('resetItem')
        })
        .catch(err => {
          return Promise.reject(err.response.data)
        })
    },
    show ({ commit }, itemID) {
      commit('selectItem', itemID)
    }
  },
  mutations: {
    selectParent (state, parentID) {
      state.parentID = parentID
    },
    selectItem (state, itemID) {
      // eslint-disable-next-line array-callback-return
      state.index.filter(data => {
        if (data.id === parseInt(itemID)) {
          state.item = data
        }
      })
    },
    populateIndex (state, data) {
      state.index = data
    },
    resetItem (state) {
      state.item = null
    }
  }
}
