<template>
  <div
    :class="customClass">
    <Field
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      :name="name"
      aria-describedby="input-true input-false input-help"
      aria-invalid="true"
      v-slot="{ meta, field }"
      :validate-on-input="false"
      :validate-on-change="false"
      :validate-on-blur="false"
    >
      <label
        :for="name"
        class="form-label">
        {{ label }}
      </label>

      <select
        v-bind="field"
        :name="name"
        :value="modelValue"
        :id="name"
        class="form-select"
        :class="{
                'vue-is-valid': meta.valid && meta.touched,
                'vue-is-invalid': !meta.valid && meta.touched,
              }">
        <option v-if="placeholder" :selected="modelValue === ''" disabled value="">{{ placeholder }}</option>
        <option
          v-for="item in options"
          :key="item.id"
          :value="item.id.toString()">
          {{ item.name }}
        </option>
      </select>
    </Field>
    <ErrorMessage as="div" :name="name" v-slot="{ message }" class="vue-invalid-feedback">
      {{ message }}
    </ErrorMessage>
  </div>
</template>

<script>
import { Field, ErrorMessage } from 'vee-validate'
export default {
  name: 'SelectInput',
  components: {
    Field,
    ErrorMessage
  },
  props: [
    'modelValue',
    'name',
    'placeholder',
    'options',
    'label',
    'customClass'
  ]
}
</script>

<style scoped>
select {
  font-size: 14px;
  font-weight: normal;
  border-radius: 7px;
  background-color: #f9f9f9;
  border-color: var(--border-default);
  color: var(--text-default);
}
.custom-background select {
  background-color: #fff;
}
.custom-padding select {
  padding: 0.625rem 0.75rem;
}

select:focus {
  font-size: 14px;
  font-weight: normal;
  border-radius: 6px;
  background-color: #f7f7f7;
  border-color: var(--border-default);
  color: var(--text-default);

  outline: unset;
  box-shadow: unset;
}

.form-label {
  font-size: 15px;
  font-weight: bold;
  color: var(--text-title);
}
</style>
