<template>
  <div
    v-if="loading.created < loading.createRequests"
    class="main-loading">
    <div class="spinner-border" role="status">
    </div>
  </div>
  <div v-else>
    <TitleModule :title="$options.title" />
    <StatsModule
      v-if="stats"
      :data="stats"
      @moduleClick="(val) => { dispatchChangeTab(val) }"/>

    <div class="affiliates-view">
      <div class="row">
        <div class="col-12">
          <div class="card border-0 mb-3">
            <div class="card-header card-header-custom bg-transparent border-0 bg_clean">Refer and Earn today!</div>
            <div class="card-header card-header-sub bg-transparent border-0">Use the link below to refer members to Sixerr and earn a flat <span class="ref-percentage">{{ setting.find(el => el.name === 'commission_percentage').value }}%</span> commission, forever!</div>
            <div class="card-body pt-4 pb-4">
              <div class="row">
                <div class="col-7 col-md-6 col-lg-5 col-xl-3">
                  <input
                    v-on:focus="$event.target.select()"
                    :value="referralLink"
                    ref="referralLink"
                    type="text"
                    id="inputReferral"
                    class="form-control"
                    readonly="readonly">
                </div>
                <div class="col-7 col-md-6 col-lg-7 col-xl-9">
                  <div class="d-flex justify-content-start align-items-center">
                    <a
                      @click="copyReferralLink"
                      class="btn btn-custom btn-order me-3"
                      data-toggle="popover"
                      data-trigger="focus" title="Dismissible popover" data-content="And here's some amazing content. It's very engaging. Right?"
                    >
                      Copy Link
                    </a>
                    <a v-if="referralLinkCopied">
                      Copied to clipboard!
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card border-0 mb-3">
            <div class="card-header card-header-custom bg-transparent border-0">Affiliates History</div>

            <div class="card-body">
              <div v-if="loading.created >= loading.createRequests && rows.length > 0 && !loading.index">
                <Datatable
                  :columns="columns">
                  <tbody>
                  <tr
                    v-for="(row, idx) in rows"
                    :key="idx">
                    <td>{{ row.date }}</td>
                    <td>{{ row.clicks }}</td>
                    <td>{{ row.registered }}</td>
                    <td>{{ centsToDollar(row.commission) }}</td>
                    <td>
                      <StatusButton :status="row.status"/>
                    </td>
                  </tr>
                  </tbody>
                </Datatable>
              </div>
              <div
                v-if="loading.created >= loading.createRequests && rows.length <= 0 && !loading.index"
                class="d-flex justify-content-center align-items-center" style="min-height: 431px;">
                <h5>No data found</h5>
              </div>
              <div
                v-if="loading.created < loading.createRequests || (loading.created >= loading.createRequests && loading.index)"
                class="d-flex justify-content-center align-items-center" style="min-height: 431px;">
                <div class="spinner-border" role="status">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatusButton from '@/components/StatusButton'
import TitleModule from '@/components/Modules/TitleModule'
import StatsModule from '@/components/Modules/StatsModule'
import Datatable from '@/components/Form/Datatable'
export default {
  title: 'Affiliates',
  name: 'AffiliatesView',
  components: { Datatable, StatsModule, TitleModule, StatusButton },
  created () {
    this.$store.dispatch('affiliate/created')
  },
  data () {
    return {
      referralLinkCopied: false
    }
  },
  methods: {
    dispatchChangeTab (tab = null) {
      console.log(tab)
    },

    copyReferralLink () {
      this.$refs.referralLink.focus()
      document.execCommand('copy')
      this.referralLinkCopied = true
    },

    /* Static global helpers */
    centsToDollar (cents) {
      return this.$centsToDollar(cents)
    }
  },
  computed: {
    loading () { return this.$store.state.affiliate.loading },
    stats () { return this.$store.state.affiliate.stats },
    rows () { return this.$store.state.affiliate.rows },
    columns () { return this.$store.state.affiliate.columns },
    setting () { return this.$store.state.setting.rows },

    referralLink () {
      return window.location.origin + this.$router.resolve({ name: 'register' }).href + '/' + this.$store.state.user.row.id
      // + this.$route.path + "/get-it/yourRandomString"
    }
  }
}
</script>

<style scoped>
.ref-percentage {
  font-size: 20px;
  font-weight: bold;
  color: var(--green-default);
}
</style>
