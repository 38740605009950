import axios from 'axios'
import authHeader from '@/store/auth.header'

export const service = {
  namespaced: true,
  state: {
    rows: []
  },
  actions: {
    index ({ commit }) {
      return axios
        .get(process.env.VUE_APP_API_URL + 'service', {
          headers: authHeader()
        })
        .then(res => {
          commit('populateRows', res.data)
        })
        .catch(err => {
          return Promise.reject(err.response.data)
        })
    }
  },
  mutations: {
    populateRows (state, data) {
      state.rows = data
    }
  }
}
