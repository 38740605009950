<template>
  <div class="register-view">
    <div class="body-auth">
      <div class="box-window justify-content-center align-items-center">
        <div>
          <div class="d-flex justify-content-center mb-4">
            <img src="@/assets/logo.svg" alt="Sixerr">
          </div>
          <div class="box">
            <h1>Get Started</h1>
            <form @submit.prevent="submitForm">
              <div
                v-if="this.error.status"
                class="form-group mb-4">
                <div class="alert alert-danger" role="alert">
                  <strong>Error!</strong> {{ this.error.message }}
                </div>
              </div>
              <div class="form-group mb-4">
                <label for="name" class="form-label">Full Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  aria-describedby="name"
                  v-model="input.name">
              </div>
              <div class="form-group mb-4">
                <label for="email" class="form-label">Email address</label>
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  aria-describedby="email"
                  v-model="input.email">
              </div>
              <div class="form-group mb-4">
                <label for="inputPassword" class="form-label">Password</label>
                <input
                  type="password"
                  class="form-control"
                  id="password"
                  v-model="input.password">
              </div>
              <div class="form-group form-check mb-4">
                <input type="checkbox" class="form-check-input" id="terms" required>
                <label class="form-check-label" for="terms">I accept the <a href="#" class="text-decoration-none forgot-anchor">Terms
                  of Service</a></label>
              </div>
              <button type="submit" class="btn btn-primary w-100">
              <span
                v-show="loading"
                class="spinner-border spinner-border-sm">
              </span>
              <span
                v-show="!loading">
                Register
              </span>
              </button>
            </form>
          </div>

          <h5 class="d-flex justify-content-center m-0 mt-3">
            Already have an account?
          </h5>
          <router-link
            :to="{ name: 'login' }"
            class="d-flex justify-content-center text-decoration-none forgot-anchor">
            Sign in
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SignUp',
  created () {
    if (this.$route.params.id !== undefined) {
      this.input.affiliate = localStorage.getItem('affiliate')
      if (!this.input.affiliate) {
        this.$store.dispatch('auth/referred', {
          referred_by: this.$route.params.id
        }).then((res) => {
          this.input.affiliate = res
          localStorage.setItem('affiliate', JSON.stringify(res))
          console.log(JSON.stringify(res))
        })
      }
    }
  },
  data () {
    return {
      loading: false,
      success: 0,
      error: {
        status: false,
        message: null
      },
      input: {
        affiliate: null,
        name: '',
        email: '',
        password: ''
      }
    }
  },
  methods: {
    submitForm () {
      this.loading = true

      if (this.input.name === '' || this.input.email === '' || this.input.password === '') {
        this.error.status = true
        this.error.message = 'Name, email address or password can not be empty'
        this.loading = false
      } else if (this.validateEmail(this.input.email)) {
        this.error.status = true
        this.error.message = 'Email address is not valid'
        this.loading = false
      } else {
        this.error.status = false
        this.error.message = null

        this.$store.dispatch('auth/register', this.input).then(
          (res) => {
            localStorage.removeItem('affiliate')
            this.$store.dispatch('user/show', res.id).then(() => {
              this.success++
            })
            this.$store.dispatch('category/index').then(() => {
              this.success++
            })
            this.$store.dispatch('service/index').then(() => {
              this.success++
            })
          },
          (error) => {
            this.error.message = ''

            if (typeof error.errors !== 'undefined') {
              const errors = error.errors
              Object.keys(errors).forEach((errorKey) => {
                errors[errorKey].forEach((errVal, errIdx) => {
                  this.error.message += (this.error.message === '') ? errVal : ', ' + errVal
                })
              })
            } else if (typeof error.message !== 'undefined') {
              this.error.message = error.message
            }

            this.error.status = true
            this.loading = false
          }
        )
      }
    },
    validateEmail (email) {
      return !(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email))
    }
  },
  computed: {
    isAuthenticated () {
      return this.$store.state.auth.authenticated
    },
    currentUser () {
      return this.$store.state.user.row
    }
  },
  watch: {
    success: function (newVal, oldVal) {
      if (newVal >= 3) {
        this.$router.push({ name: 'dashboard.index' })
      }
    }
  }
}
</script>

<style scoped>
</style>
