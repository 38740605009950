<template>
  <div
    v-if="created"
    class="d-flex justify-content-center">
    <div class="spinner-grow spinner-grow-sm me-1" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div class="spinner-grow spinner-grow-sm me-1" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div class="spinner-grow spinner-grow-sm me-1" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div
    v-if="!created">
    <img
      src="@/assets/favicon.svg"
      class="rounded-circle dropdown-toggle"
      width="140"
      height="140"
      alt="Black and White Portrait of a Man"
      loading="lazy"
      data-bs-toggle="dropdown"
      data-bs-display="static"
      aria-expanded="false"
    />

    <table class="table mt-4">
      <tr>
        <th>Name</th>
        <td>{{ this.currentUser.name }}</td>
      </tr>
      <tr>
        <th>Email</th>
        <td>Johnsmith123@email,com</td>
      </tr>
      <tr>
        <th>Password</th>
        <td>*********</td>
      </tr>
      <tr>
        <th>Timezone</th>
        <td>Asia - Islamabad (GMT+5)</td>
      </tr>
    </table>
  </div>
</template>

<script>
import * as yup from 'yup'

export default {
  name: 'ProfileSettings',
  created () {
    // this.dispatchRow()
  },
  data () {
    return {
      schema: yup.object({
        message: yup.string().required('Message is a required field')
      }),
      row: null,
      created: false,
      loadingForm: false,
      input: {
        message: '',
        attachment: null
      }
    }
  },
  methods: {
    dispatchRow () {
      this.created = true
      this.$store.dispatch('user/show', this.$route.params.id).then((res) => {
        this.created = false
        this.row = res
        console.log(res)
      })
    }
  },
  computed: {
    currentUser () {
      console.log(this.$store.state.auth.user)
      return this.$store.state.auth.user
    }
  }
}
</script>

<style scoped>
.table > :not(caption) > * > * {
  border-bottom-width: 0px;
}

th {
  font-size: 16px;
  font-weight: bold;
  color: var(--text-default);
}

th {
  font-size: 16px;
  font-weight: bold;
  color: var(--text-default);
}

td {
  font-size: 14px;
  font-weight: normal;
  color: var(--text-default);
}
</style>
