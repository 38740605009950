<template>
  <div id="successModal" class="modal fade" tabindex="-1" aria-labelledby="successModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header justify-content-center">
          <div class="icon-box">
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#2f9d94"><path d="M360-120q-100 0-170-70t-70-170v-240q0-100 70-170t170-70h240q100 0 170 70t70 170v240q0 100-70 170t-170 70H360Zm80-200 240-240-56-56-184 184-88-88-56 56 144 144Zm-80 120h240q66 0 113-47t47-113v-240q0-66-47-113t-113-47H360q-66 0-113 47t-47 113v240q0 66 47 113t113 47Zm120-280Z"/></svg>            <p class="s_msg">Order Confirmed: You're All Set!</p>

          </div>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body text-center">
          <p>Thank you for your purchase! We've received your order and are getting it ready. Keep an eye on your email for updates.</p>
          <button class="btn btn-success" data-bs-dismiss="modal">
            Place a New Order
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from 'bootstrap/js/dist/modal'

export default {
  name: 'SuccessModal',
  created () {
  },
  data () {
    return {
      instance: null
    }
  },
  mounted () {
    this.instance = new Modal(document.getElementById('successModal'))
    this.instance.show()
    console.log('success')
  },
  watch: {
    'instance._isShown': {
      deep: true,
      handler (val) {
        if (val === false) {
          // this.$emit('close')
        }
      }
    }
  }
}
</script>

<style scoped>
.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-content {
  border-radius: 8px;
  overflow: hidden;
}
.modal-header {
  display: flex;
  justify-content: center;
  background-color: #CCF1EA;
  border-bottom: none;
}
.modal-dialog.modal-dialog-centered p {
  color:var(--text-default)
}

p.s_msg {
  font-weight: 500;
    margin-bottom: 0px;
    margin-left: 10px;
    color:#2f9d94;
}

.icon-box {
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn-close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
}
.modal-body {
  padding: 20px;
}
h4 {
  margin-top: 10px;
  font-size: 24px;
}
p {
  font-size: 16px;
  color: #555;
}
.btn-success {
  background-color: #635BFF;
    border: none;
    border-radius: 6px;
    padding: 6px 10px;
    color: #fff;
    font-size: 15px;
    margin-top: 20px;
}
.btn-success:hover {
  background-color: #7578ff;
}
</style>
