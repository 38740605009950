import axios from 'axios'
import authHeader from '@/config/authHeader.config'
import { apiState } from '@/config/apiState.config'
import { apiMutation } from '@/config/apiMutation.config'
import { centsToDollar } from '@/config/globalMethods.config'

const defaultModule = 'transactions'
const defaultState = {
  loading: {
    createRequests: 1,
    created: 0,
    index: false
  }
}
const defaultMutation = {
  setStats (state, data) {
    state.stats = [
      {
        id: 1,
        title: 'Transactions',
        slug: 'transactions',
        value: data.transactions,
        info: 'total invoices',
        svg: 'fundsTransactions'
      },
      {
        id: 2,
        title: 'Available Funds',
        slug: 'funds_available',
        value: centsToDollar(data.funds_available),
        info: 'available credit',
        svg: 'fundsAvailable'
      },
      {
        id: 3,
        title: 'Total Added',
        slug: 'total',
        value: centsToDollar(data.total_funds_added),
        info: 'since joined',
        svg: 'fundsTotal'
      }
    ]
    console.log(state.stats)
  }
}

export const funds = {
  namespaced: true,
  state: JSON.parse(JSON.stringify({ ...apiState, ...defaultState })),
  actions: {
    created ({ state, dispatch, commit }) {
      if (state.stats === null) {
        dispatch('stats').then(() => {
          commit('incLoadingCreated')
        })
      }
    },
    stats ({ commit }) {
      return axios
        .get(process.env.VUE_APP_API_URL + defaultModule + '/stats', {
          headers: authHeader()
        })
        .then(res => {
          commit('setStats', res.data)
        })
        .catch(err => {
          return Promise.reject(err.response.data)
        })
    }
  },
  mutations: { ...apiMutation, ...defaultMutation }
}
