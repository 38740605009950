<template>
  <div class="vue-modal modal fade" id="checkoutModal" tabindex="-1" aria-labelledby="checkoutModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <Form @submit="onSubmit" :validation-schema="newPaymentMethod ? schema : schema2" class="needs-validation">
          <div class="modal-header p-3 border-0">
            <h5 class="modal-title">
              <div class="d-flex justify-content-start">
                <img
                  class="select-box-icon"
                  width="15"
                  src="@/assets/verified.svg">
                <div class="modal-title-main">Secured Checkout</div>
              </div>
              <p class="modal-title-sub">You are connected to a secure encrypted SSL server.</p>
            </h5>
            <h3 class="modal-title">Funds Available: {{ centsToDollar(currentUser.cash) }}</h3>
          </div>
          <div class="modal-body p-4">

            <h3 class="modal-heading mb-3 text-center">Select a payment method</h3>
            <div class="row">
              <div
                v-if="setting.length > 0 && parseInt(setting.find(el => el.name === 'stripe').value) !== 0"
                class="col-4 no-select">
                <div
                  @click="changeSelected('stripe')"
                  class="select-box text-center"
                  :class="isSelected('stripe')">
                  <img
                    class="select-box-icon"
                    width="60"
                    height="30"
                    src="@/assets/stripe.svg"
                    draggable="false">
                  <h3>Stripe</h3>
                </div>
              </div>
              <div
                v-if="setting.length > 0 && parseInt(setting.find(el => el.name === 'paypal').value) !== 0"
                class="col-4 no-select">
                <div
                  @click="changeSelected('paypal')"
                  class="select-box text-center"
                  :class="isSelected('paypal')">
                  <img
                    class="select-box-icon"
                    width="60"
                    height="30"
                    src="@/assets/paypal.svg"
                    draggable="false">
                  <h3>Paypal</h3>
                </div>
              </div>
              <div
                v-if="setting.length > 0 && parseInt(setting.find(el => el.name === 'credit_card').value) !== 0"
                class="col-4 no-select">
                <div
                  @click="changeSelected('card')"
                  class="select-box text-center"
                  :class="isSelected('card')">
                  <img
                    class="select-box-icon"
                    width="60"
                    height="30"
                    src="@/assets/master-card.svg"
                    draggable="false">
                  <h3>Credit Card</h3>
                </div>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-8 offset-2">
                <div
                  v-if="this.error.status"
                  class="form-group mb-4">
                  <div class="alert alert-danger" role="alert">
                    {{ this.error.message }}
                  </div>
                </div>
              </div>
            </div>

            <div
              v-show="isSelected('stripe')"
              class="row mt-4">
              <div class="col-8 offset-2">
                <div class="form-group mb-4">
                  <SimpleInput
                    v-model="input.cash"
                    type="number"
                    label="Cash"
                    name="cash"

                    min="0"
                    step=".01"
                  />
                </div>
                <div v-if="!newPaymentMethod">
                  <DebitCard :last4="paymentMethod.last_four" :name="paymentMethod.name" :brand="paymentMethod.brand"
                             :expiry-month="paymentMethod.exp_month" :expiry-year="paymentMethod.exp_year"/>

                  <div class="text-center mt-3">
                    <div class="d-flex justify-content-between">
                      <a
                        @click="newPaymentMethod = true"
                        class="text-decoration-none cursor-pointer ms-5">Replace Card</a>
                      <a
                        @click="removePaymentMethod"
                        class="text-decoration-none cursor-pointer me-5">Remove Card</a>
                    </div>
                  </div>
                </div>
                <div v-show="newPaymentMethod">
                  <div class="form-group mb-4">
                    <SimpleInput
                      v-model="input.name"
                      type="text"
                      label="Card Holder Name"
                      name="name"
                    />
                  </div>

                  <label for="card" class="form-label">Card</label>
                  <div id="card-element">
                  </div>
                  <div
                    v-if="paymentMethod !== null"
                    class="mt-4">
                    <a
                      @click="newPaymentMethod = false"
                      class="text-decoration-none cursor-pointer">Use saved card</a>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="!created"
              class="createdLoading">
              <span class="spinner-border spinner-border"></span>
            </div>
          </div>
          <div class="modal-footer p-3 border-0">
            <button
              @click="$emit('close')"
              type="button"
              class="modal-close"
              data-bs-dismiss="modal">Close</button>
            <button
              type="submit"
              class="btn modal-submit">
              <span
                v-show="loading"
                class="spinner-border spinner-border-sm">
              </span>
              <span
                v-show="!loading">
                Checkout
              </span>
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js'
import { Form } from 'vee-validate'
import DebitCard from '@/components/Stripe/DebitCard'
import Modal from 'bootstrap/js/dist/modal'
import SimpleInput from '@/components/Form/SimpleInput'
import * as yup from 'yup'

export default {
  name: 'CheckoutComponent',
  components: {
    Form,
    SimpleInput,
    DebitCard
  },
  props: ['cash'],
  created () {
    if (this.setting.length > 0 && parseInt(this.setting.find(el => el.name === 'stripe').value) !== 0) {
      this.selected = 'stripe'
    }

    if (this.cash) {
      this.input.cash = this.cash / (10 ** 2)
    }

    this.$store.dispatch('stripe/indexPaymentMethod').then((res) => {
      if (res !== false) {
        this.paymentMethod = res
        this.newPaymentMethod = false
        console.log('stripe/indexPaymentMethod', typeof res, res)
      }

      this.created = true
    })
  },
  data () {
    return {
      schema: yup.object({
        name: yup.string().required('Card holder name is a required field'),
        cash: yup.number().required('Cash is a required field').test(
          'maxDigitsAfterDecimal',
          'cash field must have 2 digits after decimal or less',
          (number) => Number.isInteger(number * (10 ** 2))
        )
      }),
      schema2: yup.object({
        cash: yup.number().required('Cash is a required field').test(
          'maxDigitsAfterDecimal',
          'cash field must have 2 digits after decimal or less',
          (number) => Number.isInteger(number * (10 ** 2))
        )
      }),
      created: false,
      loading: false,
      checkoutModal: null,
      paymentMethod: null,
      error: {
        status: false,
        message: null
      },
      input: {
        cash: 0,
        name: ''
      },

      selected: null,
      newPaymentMethod: true,

      stripeAPIToken: 'pk_test_51K9SnaH19zFd8WybfzEAhXyZkE8P2TQWd0aWQYkg1tKm5WO1y1i3bs4ImjFmhkAX16cxG1laBPd3ijU2zezV0eO200SCl4Y4TT',
      stripe: '',
      elements: '',
      card: '',
      intentToken: ''
    }
  },
  methods: {
    alertError (message) {
      this.error.status = true
      this.error.message = message
      this.loading = false
    },
    purchase (paymentMethodId) {
      this.$store.dispatch('stripe/purchase', {
        paymentMethodID: paymentMethodId,
        cash: this.input.cash * (10 ** 2)
      }).then((res) => {
        this.$store.dispatch('user/lazyUpdate', res.user).then(() => {
          this.input.cash = 0
          this.input.name = ''
          this.card.clear()
          this.loading = false
          this.$emit('success')
          this.checkoutModal.hide()
        })
        console.log(res)
      }).catch((err) => {
        this.alertError(err.message !== undefined ? err.message : err.error)
      })
    },

    /*
      Stripe
    */
    async configureStripe () {
      this.stripe = await loadStripe(this.stripeAPIToken)

      this.elements = this.stripe.elements()
      this.card = this.elements.create('card', {
        hidePostalCode: true,
        style: {
          base: {
            iconColor: '#666EE8',
            color: '#31325F',
            lineHeight: '40px',
            fontWeight: 300,
            fontSize: '15px',

            '::placeholder': {
              color: '#CFD7E0'
            }
          }
        }
      })

      this.card.mount('#card-element')
    },

    changeSelected (selected) {
      this.selected = selected
    },
    isSelected (selected) {
      return (this.selected === selected) ? 'selected' : null
    },
    removePaymentMethod () {
      this.loading = true

      this.$store.dispatch('stripe/removePaymentMethod').then((res) => {
        if (res !== false) {
          this.paymentMethod = null
          this.newPaymentMethod = true
        }
        this.loading = false
      })
    },

    onSubmit (values) {
      this.error.status = false
      this.error.message = ''
      this.loading = true

      if (!this.selected) {
        return this.alertError('Select a payment method.')
      }

      /* (server) create intent */
      this.$store.dispatch('stripe/createIntent').then((res) => {
        console.log('createIntent ()', res)
        this.intentToken = res

        if (this.newPaymentMethod === false && this.paymentMethod !== null) {
          /* (server) update or create subscription */
          this.purchase(this.paymentMethod.id)
        } else {
          /* (client) create payment method */
          this.stripe.confirmCardSetup(
            this.intentToken.client_secret, {
              payment_method: {
                card: this.card,
                billing_details: {
                  name: this.input.name
                }
              }
            }
          ).then(function (res) {
            if (res.error) {
              this.alertError(res.error.message)
              console.log(res.error)
            } else {
              console.log('createPaymentMethod() result', res)

              /* (server) update or create subscription */
              this.purchase(res.setupIntent.payment_method)
            }
          }.bind(this))
        }
      })
    },

    /* Static global helpers */
    centsToDollar (cents) {
      return this.$centsToDollar(cents)
    }
  },
  mounted () {
    this.checkoutModal = new Modal(document.getElementById('checkoutModal'), {
      backdrop: 'static'
    })
    this.checkoutModal.show()
    this.configureStripe()
  },
  watch: {
    'checkoutModal._isShown': {
      deep: true,
      handler (val) {
        if (val === false) {
          this.$emit('close')
        }
      }
    }
  },
  computed: {
    currentUser () { return this.$store.state.user.row },
    setting () { return this.$store.state.setting.rows }
  }
}
</script>

<style scoped>
.createdLoading {
  top: 0;
  left: 0;
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-body);
}
</style>
