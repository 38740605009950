import { createStore } from 'vuex'
import { setting } from './setting.module'
import { stripe } from './stripe.module'
import { plan } from './plan.module'
import { auth } from './auth.module'
import { user } from './user.module'
import { category } from './category.module'
import { service } from './service.module'
import { categoryService } from './category.service.module'
import { order } from './order.module'
import { ticket } from './ticket.module'
import { ticketReply } from './ticket.reply.module'
import { transaction } from './transaction.module'
import { funds } from './funds.module'
import { affiliate } from './affiliate.module'
import { dashboard } from './dashboard.module'
import { notification } from './notification.module'
import { coupon } from './coupon.module'
import { announcement } from './announcement.module'

export default createStore({
  modules: {
    setting,
    stripe,
    plan,
    auth,
    user,
    category,
    service,
    categoryService,
    order,
    ticket,
    ticketReply,
    transaction,
    funds,
    affiliate,
    dashboard,
    notification,
    coupon,
    announcement
  }
})
