import axios from 'axios'
import authHeader from '@/store/auth.header'

// const API_URL = 'https://dev.sixerr.com/api/'

export const category = {
  namespaced: true,
  state: {
    rows: []
  },
  actions: {
    index ({ commit }) {
      return axios
        .get(process.env.VUE_APP_API_URL + 'category', {
          headers: authHeader()
        })
        .then(res => {
          commit('populateRows', res.data)
        })
        .catch(err => {
          return Promise.reject(err.response.data)
        })
    }
  },
  mutations: {
    populateRows (state, data) {
      state.rows = data
    },
    appendRow (state, row) {
      state.rows.unshift(row)
    },
    updateRow (state, row) {
      const rowIndex = state.rows.findIndex(e => { return e.id === row.id })
      if (rowIndex > -1) {
        if (row.status === 'disable') {
          state.rows.splice(rowIndex, 1)
        } else {
          state.rows[rowIndex] = row
        }
      } else if (row.status === 'enable') {
        state.rows.unshift(row)
      }
    },
    destroyRow (state, rowId) {
      const rowIndex = state.rows.findIndex(e => { return e.id === rowId })
      if (rowIndex > -1) {
        state.rows.splice(rowIndex, 1)
      }
    },
    appendRowChild (state, row) {
      const rowIndex = state.rows.findIndex(e => { return e.id === row.category.id })
      if (rowIndex > -1) {
        state.rows[rowIndex].services.unshift(row)
      }
    },
    updateRowChild (state, row) {
      const rowIndex = state.rows.findIndex(e => { return e.id === row.category.id })
      if (rowIndex > -1) {
        const rowChildIndex = state.rows[rowIndex].services.findIndex(e => { return e.id === row.id })
        if (rowChildIndex > -1) {
          if (row.status === 'disable') {
            state.rows[rowIndex].services.splice(rowChildIndex, 1)
          } else {
            state.rows[rowIndex].services[rowChildIndex] = row
          }
        } else if (row.status === 'enable') {
          state.rows[rowIndex].services.unshift(row)
        }
      }
    },
    destroyRowChild (state, obj) {
      const rowIndex = state.rows.findIndex(e => { return e.id === obj.parentId })
      if (rowIndex > -1) {
        const rowChildIndex = state.rows[rowIndex].services.findIndex(e => { return e.id === obj.rowId })
        if (rowChildIndex > -1) {
          state.rows[rowIndex].services.splice(rowChildIndex, 1)
        }
      }
    }
  }
}
