import axios from 'axios'
import authHeader from '@/config/authHeader.config'
import { apiState } from '@/config/apiState.config'
import { apiMutation } from '@/config/apiMutation.config'
import { centsToDollar } from '@/config/globalMethods.config'

const defaultState = {
  namespace: 'dashboard',
  loading: {
    createRequests: 1,
    created: 0,
    index: false
  },
  recent: {
    tab: 'orders',
    orders: [],
    funds: [],
    tickets: []
  },
  announcements: [],
  activeTab: 'Orders'
}
const defaultMutation = {
  setStats (state, data) {
    state.stats = [
      {
        id: 1,
        title: 'Active Orders',
        slug: 'orders',
        value: data.orders,
        info: 'in progress',
        svg: 'dashboardOrders'
      },
      {
        id: 2,
        title: 'Available Funds',
        slug: 'funds',
        value: centsToDollar(data.funds),
        info: 'available credit',
        svg: 'dashboardFunds'
      },
      {
        id: 3,
        title: 'Active Tickets',
        slug: 'tickets',
        value: data.tickets,
        info: 'in process',
        svg: 'dashboardTickets'
      },
      {
        id: 4,
        title: 'Active Discount',
        slug: 'membership',
        value: data.membership,
        info: 'active discount',
        svg: 'dashboardMembership'
      }
    ]
    console.log(state.stats)
  },
  setOrders (state, data) {
    state.recent.orders = data
  },
  setFunds (state, data) {
    state.recent.funds = data
  },
  setTickets (state, data) {
    state.recent.tickets = data
  },
  setAnnouncements (state, data) {
    state.announcements = data
  },
  appendAnnouncement (state, row) {
    state.announcements.unshift(row)
  },
  updateAnnouncement (state, row) {
    const rowIndex = state.announcements.findIndex(e => { return e.id === row.id })
    if (rowIndex > -1) {
      if (row.status === 'disable') {
        state.announcements.splice(rowIndex, 1)
      } else {
        state.announcements[rowIndex] = row
      }
    } else if (row.status === 'enable') {
      state.announcements.unshift(row)
    }
  },
  destroyAnnouncement (state, rowId) {
    const rowIndex = state.announcements.findIndex(e => { return e.id === rowId })
    if (rowIndex > -1) {
      state.announcements.splice(rowIndex, 1)
    }
  }
}

export const dashboard = {
  namespaced: true,
  state: JSON.parse(JSON.stringify({ ...apiState, ...defaultState })),
  actions: {
    created ({ state, dispatch, commit }) {
      if (state.loading.created < state.loading.createRequests) {
        dispatch('index').then(() => {
          commit('incLoadingCreated')
        })
      }
    },
    index ({ state, commit }, url = process.env.VUE_APP_API_URL + state.namespace) {
      commit('setLoadingIndex', true)

      return axios
        .get(url, {
          headers: authHeader(),
          params: state.apiParams
        })
        .then(res => {
          console.log('res.data')
          console.log(res.data)
          commit('setStats', res.data.stats)
          commit('setOrders', res.data.recent.orders)
          commit('setFunds', res.data.recent.funds)
          commit('setTickets', res.data.recent.tickets)
          commit('setAnnouncements', res.data.announcements)
          commit('setLoadingIndex', false)
          return Promise.resolve(res.data)
        })
        .catch(err => {
          commit('setLoadingIndex', false)
          return Promise.reject(err)
        })
    },
    setRecentTab ({ state, dispatch }, data) {
      state.recent.tab = data
      return true
    }
  },
  mutations: { ...apiMutation, ...defaultMutation }
}
