import { createRouter, createWebHistory } from 'vue-router'

import LoginView from '../views/Auth/LoginView.vue'
import RegisterView from '../views/Auth/RegisterView.vue'
import ForgetView from '@/views/Auth/ForgetView'
import ResendVerificationView from '@/views/ResendVerificationView'
import SettingsView from '../views/SettingsView.vue'
import ProfileView from '../views/ProfileView.vue'
import SubscriptionView from '../views/SubscriptionView.vue'
import NotificationView from '../views/NotificationView.vue'
import DashboardView from '../views/DashboardView.vue'
import OrdersView from '../views/OrdersView.vue'
import NewOrderView from '../views/NewOrderView.vue'
import FundsView from '../views/FundsView.vue'
import FundsHistoryView from '../views/FundsHistoryView.vue'
import TicketsView from '../views/TicketsView.vue'
import ShowTicketView from '../views/ShowTicketView.vue'
import NewTicketView from '../views/NewTicketView.vue'
import AffiliatesView from '../views/AffiliatesView.vue'
import TransactionIndex from '@/views/transaction/index'
import ResetView from '@/views/Auth/ResetView'
import VerifyView from '@/views/Auth/VerifyView'
import { publicRoutes } from '@/config/globalMethods.config'
import LoginAsUserView from '@/views/Auth/LoginAsUserView'
import BillingView from '@/views/BillingView'

const routes = [
  {
    path: '/register',
    name: 'register',
    component: RegisterView,
    meta: { title: 'Register - Sixerr' }
  },
  {
    path: '/register/:id',
    name: 'register.referral',
    component: RegisterView,
    meta: { title: 'Register with Referral - Sixerr' }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: { title: 'Login - Sixerr' }
  },
  {
    path: '/login-as-user/:id/:token',
    name: 'login.as.user',
    component: LoginAsUserView,
    meta: { title: 'Login as User - Sixerr' }
  },
  {
    path: '/forget',
    name: 'auth.forget',
    component: ForgetView,
    meta: { title: 'Forget Password - Sixerr' }
  },
  {
    path: '/auth/reset/:token',
    name: 'auth.reset',
    component: ResetView,
    meta: { title: 'Reset Password - Sixerr' }
  },
  {
    path: '/auth/verify/:token',
    name: 'auth.verify',
    component: VerifyView,
    meta: { title: 'Verify Account - Sixerr' }
  },
  {
    path: '/',
    name: 'dashboard.index',
    component: DashboardView,
    meta: { title: 'Dashboard - Sixerr' }
  },
  {
    path: '/orders',
    name: 'order.index',
    component: OrdersView,
    meta: { title: 'Orders - Sixerr' }
  },
  {
    path: '/new-order',
    name: 'order.store',
    component: NewOrderView,
    meta: { title: 'New Order - Sixerr' }
  },
  {
    path: '/tickets',
    name: 'ticket.index',
    component: TicketsView,
    meta: { title: 'Tickets - Sixerr' }
  },
  {
    path: '/new-ticket',
    name: 'ticket.store',
    component: NewTicketView,
    meta: { title: 'New Ticket - Sixerr' }
  },
  {
    path: '/new-ticket/:uid',
    name: 'ticket.store.params',
    component: NewTicketView,
    meta: { title: 'New Ticket - Sixerr' }
  },
  {
    path: '/ticket/:id',
    name: 'ticket.show',
    component: ShowTicketView,
    meta: { title: 'Show Ticket - Sixerr' }
  },
  {
    path: '/profile',
    name: 'user.show',
    component: ProfileView,
    meta: { title: 'Profile - Sixerr' }
  },
  {
    path: '/billing',
    name: 'user.billing',
    component: BillingView,
    meta: { title: 'Billing - Sixerr' }
  },
  {
    path: '/subscription',
    name: 'subscription.index',
    component: SubscriptionView,
    meta: { title: 'Subscription - Sixerr' }
  },
  {
    path: '/funds',
    name: 'fund.index',
    component: FundsView,
    meta: { title: 'Funds - Sixerr' }
  },
  {
    path: '/transaction',
    name: 'transaction.index',
    component: TransactionIndex,
    meta: { title: 'Transaction - Sixerr' }
  },
  {
    path: '/auth/verify/resend',
    name: 'Resend Verification',
    component: ResendVerificationView,
    meta: { title: 'Resend Verification - Sixerr' }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: SettingsView,
    meta: { title: 'Settings - Sixerr' }
  },
  {
    path: '/notification',
    name: 'Notification',
    component: NotificationView,
    meta: { title: 'Notification - Sixerr' }
  },
  {
    path: '/funds/history',
    name: 'Funds History',
    component: FundsHistoryView,
    meta: { title: 'Funds History - Sixerr' }
  },
  {
    path: '/affiliates',
    name: 'Affiliates',
    component: AffiliatesView,
    meta: { title: 'Affiliates - Sixerr' }
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active'
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }

  const authRequired = !publicRoutes.includes(to.name)
  const authNotRequired = publicRoutes.includes(to.name)
  const loggedIn = localStorage.getItem('user')

  if (to.name === 'login.as.user' || to.name === 'auth.verify') {
    next()
  } else if (authRequired && !loggedIn) {
    next('/login')
  } else if (authNotRequired && loggedIn) {
    next('/')
  } else {
    next()
  }
})

export default router
