import axios from 'axios'
import authHeader from '@/config/authHeader.config'
import { apiState } from '@/config/apiState.config'
import { apiMutation } from '@/config/apiMutation.config'

const defaultModule = 'order'
const defaultState = {
  loading: {
    createRequests: 2,
    created: 0,
    index: false
  },
  status: [
    {
      id: 0,
      title: 'All',
      slug: 'all',
      value: null
    },
    {
      id: 1,
      title: 'Pending',
      slug: 'pending',
      value: 1
    },
    {
      id: 2,
      title: 'In Progress',
      slug: 'inProgress',
      value: 2
    },
    {
      id: 3,
      title: 'Completed',
      slug: 'completed',
      value: 3
    },
    {
      id: 4,
      title: 'Cancelled',
      slug: 'cancelled',
      value: 4
    }
  ],
  columns: [
    {
      label: 'ID',
      name: 'id'
    },
    {
      label: 'Date',
      name: 'date'
    },
    {
      label: 'Order Details',
      name: 'order_details'
    },
    {
      label: 'Quantity',
      name: 'quantity'
    },
    {
      label: 'Cost',
      name: 'cost'
    },
    {
      label: 'Start Count',
      name: 'start_count'
    },
    {
      width: '80px',
      label: 'Status',
      name: 'status'
    },
    {
      width: '80px',
      label: 'Action',
      name: 'action'
    }
  ]
}
const defaultMutation = {
  setStats (state, data) {
    state.stats = [
      {
        id: 1,
        title: 'Pending',
        slug: 'pending',
        value: data.pending,
        info: 'in pending',
        svg: 'orderPending'
      },
      {
        id: 2,
        title: 'Active',
        slug: 'inProgress',
        value: data.inProgress,
        info: 'in progress',
        svg: 'orderPending'
      },
      {
        id: 3,
        title: 'Completed',
        slug: 'completed',
        value: data.completed,
        info: 'with priority',
        svg: 'orderCompleted'
      },
      {
        id: null,
        title: 'Total',
        slug: 'total',
        value: data.total,
        info: 'orders placed',
        svg: 'orderAll'
      }
    ]
    console.log(state.stats)
  }
}

export const order = {
  namespaced: true,
  state: JSON.parse(JSON.stringify({ ...apiState, ...defaultState })),
  actions: {
    created ({ state, dispatch, commit }) {
      if (state.rows === null) {
        commit('setRows', [])
        dispatch('index').then(() => {
          commit('incLoadingCreated')
        })
      }
      if (state.stats === null) {
        dispatch('stats').then(() => {
          commit('incLoadingCreated')
        })
      }
    },
    index ({ state, commit }, url = process.env.VUE_APP_API_URL + defaultModule) {
      commit('setLoadingIndex', true)

      return axios
        .get(url, {
          headers: authHeader(),
          params: state.apiParams
        })
        .then(res => {
          commit('setRows', res.data.data)
          commit('configPagination', res.data)
          commit('setLoadingIndex', false)
          return Promise.resolve(res.data)
        })
        .catch(err => {
          commit('setLoadingIndex', false)
          return Promise.reject(err.response.data)
        })
    },
    store ({ commit }, data) {
      return axios
        .post(process.env.VUE_APP_API_URL + defaultModule, data, {
          headers: authHeader()
        })
        .then(res => {
          res.data.forEach(el => {
            commit('storeRow', el)
            commit('incStats', 'pending')
            this.commit('user/decCash', el.cost)
            console.log('order/store.res', el)
          })
          return Promise.resolve(res.data)
        })
        .catch(err => {
          return Promise.reject(err.response.data)
        })
    },
    stats ({ commit }) {
      return axios
        .get(process.env.VUE_APP_API_URL + defaultModule + '/stats', {
          headers: authHeader()
        })
        .then(res => {
          commit('setStats', res.data)
        })
        .catch(err => {
          return Promise.reject(err.response.data)
        })
    },
    setState ({ state, dispatch }, data) {
      console.log(data)
      if (data.loading !== undefined && data.loading.created !== undefined) {
        state.loading.created = data.loading.created
      }
      if (data.loading !== undefined && data.loading.index !== undefined) {
        state.loading.index = data.loading.index
      }
      if (data.searchBox !== undefined) {
        state.searchBox = data.searchBox
        if (state.searchBox === false && state.apiParams.search !== '') {
          state.apiParams.search = ''
          dispatch('index')
        }
      }
      if (data.search !== undefined && state.apiParams.search !== data.search) {
        state.apiParams.search = data.search
        dispatch('index')
      }
      if (data.status !== undefined) {
        state.apiParams.status = data.status
        dispatch('index')
      }
      return true
    }
  },
  mutations: { ...apiMutation, ...defaultMutation }
}
