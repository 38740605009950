import axios from 'axios'
import authHeader from '@/store/auth.header'

export const plan = {
  namespaced: true,
  state: {
  },
  actions: {
    index () {
      return axios
        .get(process.env.VUE_APP_API_URL + 'plan', {
          headers: authHeader()
        })
        .then(res => {
          return Promise.resolve(res.data)
        })
        .catch(err => {
          return Promise.reject(err.response.data)
        })
    }
  },
  mutations: {
  }
}
