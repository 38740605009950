<template>
  <footer class="position-fixed left-0 bottom-0 w-100 d-flex flex-wrap justify-content-between align-items-center py-3 footer_global">
    <div class="col-md-12 align-items-center justify-content-start">
      <span class="text-muted">© {{ currentYear }}, Sixerr.com - All Rights Reserved.</span>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterAuthComponent',
  data () {
    return {
      currentYear: new Date().getFullYear()
    }
  }
}
</script>

<style scoped>
footer {
  background-color: var(--bg-body-auth);
}
.text-muted {
  color: var(--text-default)!important;
  font-size: 12px;
}
.external-link:hover {
  color: var(--text-title)!important;
}

</style>
