<template>
  <div
    v-if="loading.created < loading.createRequests"
    class="main-loading">
    <div class="spinner-border" role="status">
    </div>
  </div>
  <div v-else>
    <TitleModule :title="$options.title" />

    <div class="vue-view">
      <div class="card border-0 mb-3">

        <CardHeaderModule
          :redirect="{ name: 'Add Funds', slug: 'fund.index' }"
          :status="status"
          :selected="apiParams.status"
          :searchBox="searchBox"
          :searchValue="apiParams.search"
          @moduleClick="(val) => { dispatchChangeTab(val) }"
          @modalSearch="(val) => { dispatchSearch(val) }"
          @modalSearchBox="(val) => { dispatchSearchBox(val) }"/>

        <div class="card-body">
          <div v-if="loading.created >= loading.createRequests && rows.length > 0 && !loading.index">
            <Datatable
              :columns="this.columns">
              <tbody>
              <tr
                v-for="row in rows"
                :key="row.id"
              >
                <td>{{ timestampToDate(row.created_at) }}<br><span>{{ timestampToTime24(row.created_at) }}</span></td>
                <td>{{ row.uid }}</td>
                <td>{{ row.method }}<br><span>{{ row.method_id }}</span></td>
                <td>{{ this.centsToDollar(row.amount) }}</td>
                <td>
                  <img
                    width="12"
                    height="12"
                    src="@/assets/ic-download.svg">
                </td>
                <td>
                  <StatusButton :status="row.status"/>
                </td>
                <td class="text-center">
                  <div class="btn-group dropstart">
                    <svg
                      class="action"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 16 16">
                      <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"></path>
                    </svg>
                    <ul class="dropdown-menu">
                      <li>
                        <router-link
                          :to="{ name: 'ticket.store.params', params: { uid: row.uid } }"
                          class="dropdown-item"
                          aria-current="page">
                          Open Ticket
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
              </tbody>
            </Datatable>
            <Pagination
              :pagination="this.pagination"
              @prev="dispatchIndex(pagination.prevPageUrl)"
              @next="dispatchIndex(pagination.nextPageUrl)"/>
          </div>
          <div
            v-if="loading.created >= loading.createRequests && rows.length <= 0 && !loading.index"
            class="d-flex justify-content-center align-items-center" style="min-height: 431px;">
            <h5>No data found</h5>
          </div>
          <div
            v-if="loading.created < loading.createRequests || (loading.created >= loading.createRequests && loading.index)"
            class="d-flex justify-content-center align-items-center" style="min-height: 431px;">
            <div class="spinner-border" role="status">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatusButton from '@/components/StatusButton'
import Datatable from '@/components/Form/Datatable'
import Pagination from '@/components/Form/Pagination'
import TitleModule from '@/components/Modules/TitleModule'
import CardHeaderModule from '@/components/Modules/CardHeaderModule'

export default {
  title: 'Transactions',
  name: 'OrdersView',
  components: {
    CardHeaderModule,
    TitleModule,
    StatusButton,
    Datatable,
    Pagination
  },
  created () {
    this.$store.dispatch('transaction/created')
  },
  methods: {
    dispatchSearch (data) { this.$store.dispatch('transaction/setState', { search: data }) },
    dispatchSearchBox (value) { this.$store.dispatch('transaction/setState', { searchBox: value }) },
    dispatchChangeTab (tab = null) { this.$store.dispatch('transaction/setState', { status: tab }) },
    dispatchIndex (url) { this.$store.dispatch('transaction/index', url) },

    /* Static global helpers */
    centsToDollar (cents) { return this.$centsToDollar(cents) },
    timestampToDate (timestamp) { return this.$timestampToDate(timestamp) },
    timestampToTime24 (timestamp) { return this.$timestampToTime24(timestamp) }
  },
  computed: {
    loading () { return this.$store.state.transaction.loading },
    stats () { return this.$store.state.transaction.stats },
    rows () { return this.$store.state.transaction.rows },
    pagination () { return this.$store.state.transaction.pagination },
    apiParams () { return this.$store.state.transaction.apiParams },
    status () { return this.$store.state.transaction.status },
    columns () { return this.$store.state.transaction.columns },
    searchBox () { return this.$store.state.transaction.searchBox }
  }
}
</script>
