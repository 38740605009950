import axios from 'axios'
import authHeader from '@/store/auth.header'

export const stripe = {
  namespaced: true,
  state: {
  },
  actions: {
    createCheckoutSession ({ commit }, planID) {
      return axios
        .get(process.env.VUE_APP_API_URL + 'stripe/checkout/session/' + planID, {
          headers: authHeader()
        })
        .then(res => {
          return Promise.resolve(res.data)
        })
        .catch(err => {
          return Promise.reject(err.response.data)
        })
    },
    subscriptionCancel ({ commit }) {
      return axios
        .get(process.env.VUE_APP_API_URL + 'stripe/subscription/cancel', {
          headers: authHeader()
        })
        .then(res => {
          return Promise.resolve(res.data)
        })
        .catch(err => {
          return Promise.reject(err.response.data)
        })
    },

    createIntent ({ commit }, planID) {
      return axios
        .get(process.env.VUE_APP_API_URL + 'stripe/intent/create', {
          headers: authHeader()
        })
        .then(res => {
          return Promise.resolve(res.data)
        })
        .catch(err => {
          return Promise.reject(err.response.data)
        })
    },
    purchase ({ commit }, data) {
      return axios
        .post(process.env.VUE_APP_API_URL + 'stripe/purchase', data, {
          headers: authHeader()
        })
        .then(res => {
          return Promise.resolve(res.data)
        })
        .catch(err => {
          return Promise.reject(err.response.data)
        })
    },
    subscriptionUpdate ({ commit }, data) {
      return axios
        .post(process.env.VUE_APP_API_URL + 'stripe/subscription/update', data, {
          headers: authHeader()
        })
        .then(res => {
          return Promise.resolve(res.data)
        })
        .catch(err => {
          return Promise.reject(err.response.data)
        })
    },
    indexPaymentMethod ({ commit }, planID) {
      return axios
        .get(process.env.VUE_APP_API_URL + 'stripe/payment_method', {
          headers: authHeader()
        })
        .then(res => {
          return Promise.resolve(res.data)
        })
        .catch(err => {
          return Promise.reject(err.response.data)
        })
    },
    removePaymentMethod () {
      return axios
        .delete(process.env.VUE_APP_API_URL + 'stripe/payment_method', {
          headers: authHeader()
        })
        .then(res => {
          return Promise.resolve(res.data)
        })
        .catch(err => {
          return Promise.reject(err.response.data)
        })
    },
    createPaymentMethod ({ commit }, data) {
      return axios
        .post(process.env.VUE_APP_API_URL + 'stripe/payment_method', data, {
          headers: authHeader()
        })
        .then(res => {
          return Promise.resolve(res.data)
        })
        .catch(err => {
          return Promise.reject(err.response.data)
        })
    }
  },
  mutations: {
  }
}
