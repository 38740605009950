<template>
  <div
    v-if="loading.created < loading.createRequests"
    class="main-loading">
    <div class="spinner-border" role="status">
    </div>
  </div>
  <div v-else>
    <TitleModule :title="$options.title" />
    <StatsModule
      v-if="stats"
      :data="stats"
      @moduleClick="(val) => { dispatchChangeView(val) }"/>

    <div class="row">
    <div class="col-12">
      <div class="card border-0 mb-3">
        <div class="card-header card-header-custom bg-transparent border-0 bg_clean">How do you want to pay?</div>
        <div class="card-header card-header-sub bg-transparent border-0">Please select a payment method to add funds to your account.</div>
        <div class="card-body pt-4 pb-4">
          <div class="row mb-4">
            <div
              v-if="setting.length > 0 && parseInt(setting.find(el => el.name === 'stripe').value) !== 0"
              class="col-4 no-select">
              <div
                @click="changeSelected('stripe')"
                class="select-box d-flex justify-content-between align-items-center"
                :class="isSelected('stripe')">
                <div>
                  <h3>Stripe</h3>
                  <p>Pay using Visa, Mastercard, AMX</p>
                </div>
                <img
                  class="select-box-icon"
                  width="60"
                  height="30"
                  src="@/assets/stripe.svg"
                  draggable="false">
              </div>
              <StripeCheckoutComponent v-if="isSelected('stripe')" @close="changeSelected(null)"/>
            </div>
            <div
              v-if="setting.length > 0 && parseInt(setting.find(el => el.name === 'paypal').value) !== 0"
              class="col-4 no-select">
              <div
                @click="changeSelected('Paypal')"
                class="select-box d-flex justify-content-between align-items-center"
                :class="isSelected('Paypal')">
                <div>
                  <h3>Paypal</h3>
                  <p>Pay using Visa, Mastercard, AMX</p>
                </div>
                <img
                  class="select-box-icon"
                  width="80"
                  height="30"
                  src="@/assets/paypal.svg"
                  draggable="false">
              </div>
            </div>
            <div
              v-if="setting.length > 0 && parseInt(setting.find(el => el.name === 'credit_card').value) !== 0"
              class="col-4 no-select">
              <div
                @click="changeSelected('CreditCard')"
                class="select-box d-flex justify-content-between align-items-center"
                :class="isSelected('CreditCard')">
                <div>
                  <h3>Credit Cards</h3>
                  <p>Pay using Visa, Mastercard, AMX</p>
                </div>
                <img
                  class="select-box-icon"
                  width="60"
                  height="30"
                  src="@/assets/master-card.svg"
                  draggable="false">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import StripeCheckoutComponent from '@/components/Modals/StripeCheckoutComponent'
import TitleModule from '@/components/Modules/TitleModule'
import StatsModule from '@/components/Modules/StatsModule'

export default {
  title: 'Funds',
  name: 'FundsView',
  components: {
    StatsModule,
    TitleModule,
    StripeCheckoutComponent
  },
  created () {
    this.$store.dispatch('funds/created')
  },
  data () {
    return {
      selected: null
    }
  },
  methods: {
    dispatchChangeView (tab = null) {
      switch (tab) {
        case 1:
          this.$router.push({ name: 'transaction.index' })
          break
      }
    },

    changeSelected (selected) {
      console.log(selected)
      this.selected = selected
    },
    isSelected (selected) {
      return (this.selected === selected) ? 'selected' : null
    }
  },
  computed: {
    loading () { return this.$store.state.funds.loading },
    stats () { return this.$store.state.funds.stats },
    setting () { return this.$store.state.setting.rows }
  }
}
</script>

<style scoped>

.checkout-box {
  /*border: 1px solid var(--border-default);*/
  /*box-shadow: 0px 6px 6px rgb(0 0 0 / 4%), 0px 0px 42px rgb(0 0 0 / 4%);*/
}

.select-box {
  border: 3px solid var(--border-default);
  border-radius: 6px;
  position: relative;
  padding: 20px;
  color: var(--text-default);
  background-color: #f7f7f7;
  cursor: pointer;
}

.select-box:hover {
  background-color: var(--white);
  box-shadow: 0px 6px 6px rgb(0 0 0 / 4%), 0px 0px 42px rgb(0 0 0 / 4%);
  transition: 0.3s;
}

.select-box.active {
  background-color: var(--white);
  border: 3px solid var(--purple-default);
  transition: 0.3s;
}

.select-box h3 {
  font-size: 14px;
  font-weight: bold;
  color: var(--text-title);
}

.select-box p {
  font-size: 12px;
  font-weight: normal;
  color: var(--text-default);
  margin: unset;
}

select, input, textarea {
  font-size: 14px;
  font-weight: normal;
  border-radius: 7px;
  background-color: #f7f7f7;
  border-color: var(--border-default);
  color: var(--text-default);
}

.nav-link {
  font-size: 14px;
  color: var(--text-default);
  padding: 0;
  margin: 0.5rem 0 0 0;
}

.nav-info {
  font-size: 11px;
  margin: 0;
}

.nav-link.active {
  /*border-bottom: 2px solid var(--purple-default);*/
  font-weight: bold;
  color: var(--green-default);
}
</style>
