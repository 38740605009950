<template>
  <div class="card-header bg-transparent bg_clean">
    <div class="d-flex justify-content-between">
      <div class="d-flex w-100">
        <div class="card-header-button card-header-button-left">
          <span
            v-for="item in status"
            :key="item.id"
            @click="$emit('moduleClick', item.value)"
            :class="selected === item.value && 'active'">
            {{ item.title }}
          </span>
        </div>
        <div class="input-group search_box">
          <img
            @click="$emit('modalSearchBox', !searchBox)"
            class="input-group-text cursor-pointer"
            src="@/assets/ic-search.svg">
          <input
            @blur="$emit('modalSearch', $event.target.value)"
            v-show="searchBox"
            ref="searchInput"
            :value="searchValue"
            type="text"
            class="form-control"
            placeholder="Search ..">
        </div>
      </div>
      <div class="card-header-button">
        <router-link
          :to="{ name: redirect.slug }"
          class="btn btn-custom btn-action"
          aria-current="page">
          {{ redirect.name }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardHeaderModule',
  props: ['redirect', 'status', 'selected', 'searchBox', 'searchValue'],
  watch: {
    searchBox: function (newVal, oldVal) {
      if (newVal === true) {
        const el = this.$refs.searchInput
        this.$nextTick(() => {
          el.focus()
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
