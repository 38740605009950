<template>
  <div class="profile-view">
    <div class="card border-0 mb-3">

      <HeaderSettings />

      <div class="card-body">
        <div
          v-if="created"
          class="d-flex justify-content-center">
          <div class="spinner-grow spinner-grow-sm me-1" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <div class="spinner-grow spinner-grow-sm me-1" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <div class="spinner-grow spinner-grow-sm me-1" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div
          v-if="!created">
          <div class="row">
            <div class="col-12">
              <div
                v-if="error.status"
                class="form-group mb-4">
                <div class="alert alert-danger" role="alert">
                  {{ error.message }}
                </div>
              </div>
            </div>
            <div class="col-12 col-md-12 col-lg-5 mb-5 mb-lg-0 text-center">
              <div v-if="edit" class="position-relative">
                <input
                  @change="onFileChange"
                  name="avatar"
                  ref="avatar"
                  type="file"
                  accept="image/*"
                  class="d-none">
                <div
                  @click="() => { $refs.avatar.click() }"
                  class="edit-picture">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" data-v-3f3a6d72=""><path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48" data-v-3f3a6d72=""></path></svg>
                </div>
              </div>
              <img
                v-if="input.avatarUrl"
                :src="input.avatarUrl"
                class="rounded-circle dropdown-toggle"
                alt="Black and White Portrait of a Man"
                loading="lazy"
                data-bs-toggle="dropdown"
                data-bs-display="static"
                aria-expanded="false"
              />
              <img
                v-else-if="currentUser.avatar"
                :src="currentUser.avatar.original_url"
                class="rounded-circle dropdown-toggle"
                alt="Black and White Portrait of a Man"
                loading="lazy"
                data-bs-toggle="dropdown"
                data-bs-display="static"
                aria-expanded="false"
              />
              <img
                v-else
                src="@/assets/favicon.svg"
                class="rounded-circle dropdown-toggle"
                alt="Black and White Portrait of a Man"
                loading="lazy"
                data-bs-toggle="dropdown"
                data-bs-display="static"
                aria-expanded="false"
              />
            </div>
            <div class="col-12 col-md-12 col-lg-7">
              <table class="table h-100 m-0">
                <tr>
                  <th>Name</th>
                  <td v-if="edit">
                    <input
                      v-model="input.name"
                      type="text">
                  </td>
                  <td v-else>
                    {{ currentUser.name }}
                  </td>
                </tr>
                <tr>
                  <th>Email</th>
                  <td v-if="edit">
                    <input
                      v-model="input.email"
                      type="email">
                  </td>
                  <td v-else>
                    {{ currentUser.email }}
                  </td>
                </tr>
                <tr>
                  <th>Password</th>
                  <td v-if="edit">
                    <input
                      v-model="input.password"
                      type="password">
                  </td>
                  <td v-else>
                    ***********
                  </td>
                </tr>
                <tr v-if="edit">
                  <th>Password Confirm</th>
                  <td>
                    <input
                      v-model="input.password_confirmation"
                      type="password">
                  </td>
                </tr>
                <tr>
                  <th>Timezone</th>
                  <td>{{ timezoneWithAbbr() }}</td>
                </tr>
              </table>
            </div>
            <div class="col-12">
              <div
                v-if="!edit"
                class="d-flex justify-content-end">
                <button
                  @click="edit = true"
                  type="button"
                  class="btn btn-custom btn-action">
                  Edit
                </button>
              </div>
              <div
                v-if="edit"
                class="d-flex justify-content-end">
                <button
                  @click="edit = false"
                  type="button"
                  class="btn btn-custom btn-cancel me-3">
                  Back
                </button>
                <button
                  @click="submitForm"
                  class="btn btn-custom btn-action">
                  <span
                    v-show="loading"
                    class="spinner-border spinner-border-sm">
                  </span>
                  <span
                    v-show="!loading">
                    Save
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderSettings from '@/components/HeaderSettings'

export default {
  name: 'ProfileView',
  components: {
    HeaderSettings
  },
  created () {
    this.input.email = this.currentUser.email
    this.input.name = this.currentUser.name
  },
  data () {
    return {
      created: false,
      edit: false,
      loading: false,
      error: {
        status: false,
        message: null
      },
      input: {
        email: '',
        name: '',
        password: '',
        password_confirmation: '',
        avatar: null,
        avatarUrl: null
      }
    }
  },
  methods: {
    submitForm () {
      this.loading = true
      this.error.status = false
      this.error.message = ''

      const formData = JSON.parse(JSON.stringify(this.input))
      formData.avatar = this.input.avatar
      formData._method = 'put'

      if (formData.email === this.currentUser.email) {
        delete formData.email
      }

      if (formData.password === '') {
        delete formData.password
        delete formData.password_confirmation
      }

      this.$store.dispatch('user/update', { id: this.currentUser.id, data: formData }).then(
        (res) => {
          this.row = res.data
          this.loading = false
          this.edit = false
          this.input.password = ''
          this.input.password_confirmation = ''
          this.input.avatar = null
          this.input.avatarUrl = null
        },
        (error) => {
          this.error.message = ''

          if (typeof error.errors !== 'undefined') {
            const errors = error.errors
            Object.keys(errors).forEach((errorKey) => {
              errors[errorKey].forEach((errVal, errIdx) => {
                this.error.message += (this.error.message === '') ? errVal : ', ' + errVal
              })
            })
          } else if (typeof error.message !== 'undefined') {
            this.error.message = error.message
          }

          this.error.status = true
          this.loading = false
        }
      )
    },

    /* helpers */
    onFileChange (e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      this.input.avatar = files[0]
      this.input.avatarUrl = URL.createObjectURL(this.input.avatar)
      console.log(files[0])
      console.log(this.input.avatarUrl)
    },

    timezoneWithAbbr () {
      return this.$timezoneWithAbbr()
    }
  },
  computed: {
    currentUser () {
      return this.$store.state.user.row
    }
  }
}
</script>

<style scoped>
tr th {
  width: 150px;
}
tr:hover th,
tr:hover td {
  background-color: unset;
  border-color: var(--hover-border-default);
}
.edit-picture {
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  width: 220px;
  height: 220px;
  border-radius: 50%;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
input {
  border: unset;
  padding: unset;
  height: 100%;
  width: 100%;
}
</style>
