import axios from 'axios'
import authHeader from '@/config/authHeader.config'
import { apiState } from '@/config/apiState.config'
import { apiMutation } from '@/config/apiMutation.config'
import { getErrorFromRequest } from '@/config/globalMethods.config'

const defaultState = {
  namespace: 'notification',
  loading: {
    createRequests: 1,
    created: 0,
    index: false
  },
  checked: []
}
const defaultMutation = {
  setChecked (state, data) {
    state.checked = data
    console.log(state.checked)
  }
}

export const notification = {
  namespaced: true,
  state: JSON.parse(JSON.stringify({ ...apiState, ...defaultState })),
  actions: {
    created ({ state, dispatch, commit }) {
      if (state.rows === null) {
        commit('setRows', [])
        dispatch('index').then(() => {
          commit('incLoadingCreated')
        })
      }
    },
    index ({ state, commit }, url = process.env.VUE_APP_API_URL + state.namespace) {
      commit('setLoadingIndex', true)
      commit('resetAlerts')

      return axios
        .get(url, {
          headers: authHeader()
        })
        .then(res => {
          commit('setRows', res.data.notifications)
          commit('setChecked', res.data.user_notifications)
          commit('setLoadingIndex', false)
          return Promise.resolve(res.data)
        })
        .catch(err => {
          commit('setDangerAlert', getErrorFromRequest(err))
          commit('setLoadingIndex', false)
          return Promise.reject(err.response.data)
        })
    },
    update ({ state, commit }, url = process.env.VUE_APP_API_URL + state.namespace) {
      commit('setLoadingIndex', true)
      commit('resetAlerts')

      return axios
        .post(url, {
          notifications: state.checked
        }, {
          headers: authHeader()
        })
        .then(res => {
          commit('setSuccessAlert', 'saved')
          commit('setLoadingIndex', false)
          return Promise.resolve(res.data)
        })
        .catch(err => {
          commit('setDangerAlert', getErrorFromRequest(err))
          commit('setLoadingIndex', false)
          return Promise.reject(err.response.data)
        })
    }
  },
  mutations: { ...apiMutation, ...defaultMutation }
}
